import React from 'react'

import HeaderComponent from '../Component/HeaderComponent';
import Conference from '../Component/Conference';
import OtherComponent from '../Component/OtherComponent';
import Footer from '../Component/Footer';
import NavbarMenu from './NavbarMenu';

function MainComponent() {
  return (
    <>
        <div className='container-fluid p-1' style={{margin:"0px auto"}}>
          <div>
              <NavbarMenu />
          </div>
        <div className='imgmu'>
          <HeaderComponent />
        </div>
        <div>
          <Conference />
        </div>
       
        <div className='mt-4'>
          <OtherComponent />
        </div>
        <div className='mt-4'>
          <Footer />
        </div>
      </div>
    </>
  );
}

export default MainComponent