import React from 'react'
import logo_mu from '../images/logo_mu.jpg'


function Conference() {
  return (
    <>
    <div className='row'>
        <div className='col-12'>
    <div className='mt-5 p-3 bg-light'>
    <div className='row p-2'>
        <div className='col text-center fs-2 font-weight-bold text-wrap p-3 text-dark'>International Conference on Innovations in Data Science 2024 (ICIDS - 2024)</div>
    </div>
    <div className=''>
   
    <div className='row p-2'>
        <div className='col text-center fs-5 font-weight-bold text-wrap text-danger'>28<sup>th</sup> - 29<sup>th</sup> November, 2024 (Hybrid Mode)</div>
    </div>
    <div className='row p-2'>
        <div className='col text-center fs-5 font-weight-bold text-wrap text-dark'>Organized by</div>
    </div>
    <div className='row'>
        <div className='col col-4'></div>
        <div className='col col-4 border border-2' style={{margin:"0px auto;"}}><img src={logo_mu} alt="Logo" className='img-fluid' /></div>
        <div className='col col-4'></div>
    </div>
    <div className='row'>
        <div className='col text-center fs-5 font-weight-bold text-wrap text-dark'>Department of Data Science and Engineering</div>
    </div>
    <div className='row'>
        <div className='col text-center fs-5 font-weight-bold text-wrap text-dark'>School of Information, Security and Data Science</div>
    </div>
    
    </div>
    </div>
    </div>
    </div>
    </>
  )
}

export default Conference