import React from 'react'
import line2 from '../images/line2.jpg'
import Footer from './Footer'
import NavbarMenu from './NavbarMenu'

function Registration() {
  return (
    <>

      <div className='container-fluid text-justify'>
        <div>
          <NavbarMenu />
        </div>
        <div className='row' style={{marginTop:"90px"}}>
          <div className='col col-10 fs-2 p-2 mb-4' style={{ margin: "0px auto" }}>
            <img src={line2} alt='' style={{ width: "20px", height: "40px" }} /><span className='m-4 fontFamily'>Registration Details</span>
          </div>

          <div className='col col-8 fontFamily' style={{ margin: "0px auto" }}>
          The authors agree that if the paper is accepted for oral/ poster presentation, at least one of
           the author will register for the conference and present the paper at conference venue.
            The registration fee <b>(inclusive of 18% GST)</b> is given below for your reference:
            
          </div>

          <div className='col col-8 border border-1 text-center mt-4' style={{ margin: "0px auto" }}>
            
            <table className="table table-striped fontFamily">
              <thead>
                <tr>
                  <th scope="col">Category</th>
                  <th scope="col">Authors from India (in INR)</th>
                  <th scope="col">Authors from Abroad (in USD)</th>
                </tr>
              </thead>
              <tbody>

                <tr>
                  <td>Research Scholar and Student</td>
                  <td>7000</td>
                  <td>200</td>
                </tr>

                <tr>
                  <td>Academician (Faculty Member)</td>
                  <td>8500</td>
                  <td>250</td>
                </tr>

                <tr>
                  <td>Industry Professional</td>
                  <td>15000</td>
                  <td>300</td>
                </tr>

                <tr>
                  <td>Co-Author Registration (Academia)</td>
                  <td>2500</td>
                  <td>60</td>
                </tr>

                <tr>
                  <td>Co-Author Registration (Industry)</td>
                  <td>3500</td>
                  <td>85</td>
                </tr>

              </tbody>
            </table>


          </div>

          <div className='col col-8 border border-1 mt-5' style={{ margin: "0px auto" }}>
            
            <table className="table fontFamily">
           
              <tbody>
                <tr>
                  <td>
                  ➤ Access to Keynote talks
                  </td>
                </tr>
                <tr>
                  <td>
                  ➤ Access to paper presentation sessions
                  </td>
                </tr>
                <tr>
                  <td>
                  ➤ Networking sessions
                  </td>
                </tr>
                <tr>
                  <td>
                  ➤ Conference lunch and refreshment*
                  </td>
                </tr>
                <tr>
                  <td>
                  ➤ Tea and Coffee Breaks*
                  </td>
                </tr>
                <tr>
                  <td>
                  ➤ Conference kit*
                  </td>
                </tr>
                <tr>
                  <td>
                  ➤ Participation Certificate
                  </td>
                </tr>
                </tbody>
            </table>
          </div>

         
        </div>

        <div className='row mt-5'>
          <div className='col col-10 fs-2 p-2 mb-4' style={{ margin: "0px auto" }}>
            <img src={line2} alt='' style={{ width: "20px", height: "40px" }} /><span className='m-4 fontFamily'>Important Instructions</span>
          </div>
          <div className='col col-8 border border-1 mt-4' style={{ margin: "0px auto" }}>
            
            <table className="table table-striped fontFamily">
            <thead>
                <tr>
                  <th></th>
                </tr>
              </thead>

              <tbody>

                <tr>
                  <td>
                    1. For successful registration, at least one author should register before deadline.
                  </td>

                </tr>
                <tr>
                  <td>
                  2.	Registration fee includes participation at the conference, food, and conference kit.
                  </td>
                </tr>
                <tr>
                  <td>
                  3.	Boarding and lodging will be borne by participant only.
                  </td>
                </tr>
                <tr>
                  <td>
                  4.	Standard page limit of 10 pages (in Springer Format) will be applicable to all submitted manuscripts.
                  </td>
                </tr>
                <tr>
                  <td>
                  5.	After payment, note down transaction ID and save a snapshot of the payment. Transaction ID and snapshots are required for registration.
                  </td>
                </tr>
                <tr>
                  <td>
                  6.	Presentation certificate will be issued to presenter only. The presentation certificate will also include the name of all authors, but the affiliation of presenter only will be included.
                  </td>
                </tr>
                <tr>
                  <td>
                  7.	If multiple papers are accepted for publication and presentation by the same author(s), each paper requires a registration fee to be included in the proceedings.
                  </td>
                </tr>
                <tr>
                  <td>
                  8.	Once registration is confirmed, the corresponding author will receive a copy of publishing agreement from us. The corresponding author needs to submit the signed and scanned copy of the publishing agreement. The details regarding submission of publishing agreement will be sent via email.
                  </td>
                </tr>
                <tr>
                  <td>
                  9.	Do not inquire about registration status via email/ phone. You will be notified via email once the payment information is updated with us from finance section.
                  </td>
                </tr>
              </tbody>
            </table>


          </div>
        </div>

        <div className='mt-4'>
          <Footer />
        </div>

      </div>
    </>
  )
}

export default Registration