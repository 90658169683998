import React from 'react'
import Footer from './Footer'
import NavbarMenu from './NavbarMenu'
import { Link } from 'react-router-dom'

function Contact() {
  return (
    <>

      <div className='container-fluid fontFamily' style={{ marginTop: "90px"}}>
        <div>
          <NavbarMenu />
        </div>
       
          <div className='row'>
            <div className='col col-11 text-center fs-4 p-2 text-wrap bgColorDemo text-darh mt-3' style={{margin:"0px auto"}}>
               Contact Us
            </div>
          </div>
         

            <div className='row p-2'>
          <div className='col col-11' style={{margin:"0px auto"}}>

            <div className=''>
              <div className="card mt-2">
                <div className="card-header text-center font-weight-bold fs-4">
                FOR REGISTRATION AND CONFERENCE INQUIRY
                </div>
                <ul className="list-group list-group-flush">
                  <li class="list-group-item text-center">
                   
                    <span style={{fontSize:"18px"}}>Email<br />
                    <span style={{fontSize:"14px"}}>icids2k24@gmail.com</span></span>
                  </li>
                </ul>
              </div>
            </div>


          </div>
        </div>

          <div className='row'>
            <div className='col col-11' style={{margin:"0px auto"}}>

            <div className='row p-2'>
          <div className='col'>

            <div className=''>
              <div className="card mt-2">
                <div className="card-header text-center font-weight-bold fs-4">
                LOCAL ORGANIZING CHAIR
                </div>
                <ul className="list-group list-group-flush">
                  <li class="list-group-item text-center">
                   
                    
                    <span style={{fontSize:"18px"}}>Dr. Anju George<br />
                    <span style={{fontSize:"14px"}}>Assistant Professor,
                          Department of Data Science and Engineering,
                          Manipal University Jaipur<br />
                          Phone: +91 98461 32699<br />
                          Email: anju.george@jaipur.manipal.edu</span>
                    </span>
                  </li>

                  <li class="list-group-item text-center">
                   
                    
                    <span style={{fontSize:"18px"}}>Ms. Shatabdi Basu<br />
                    <span style={{fontSize:"14px"}}>Assistant Professor, Department of Data Science and Engineering,
                    Manipal University Jaipur<br />
                    Phone: +91 70039 04117<br />
                    Email: shatabdi.basu@jaipur.manipal.edu</span>
                    </span>
                  </li>

                </ul>
              </div>
            </div>


          </div>
        </div>

            </div>
          </div>

          <div className='row'>
            <div className='col col-11' style={{margin:"0px auto"}}>

            <div className='row p-2'>
          <div className='col'>

            <div className=''>
              <div className="card mt-2">
                <div className="card-header text-center font-weight-bold fs-4">
                Venue
                </div>
                <ul className="list-group list-group-flush">
                  <li class="list-group-item text-center">
                   
                    
                    <span style={{fontSize:"18px"}}>Manipal University Jaipur,
                      Jaipur-Ajmer Express Highway,
                      Dehmi Kalan, Near GVK Toll Plaza,
                      Jaipur, Rajasthan 303007</span>
                   
                  </li>

                </ul>
              </div>
            </div>


          </div>
        </div>

            </div>
          </div>

          <div className='row'>
            <div className='col col-11' style={{margin:"0px auto"}}>

            <div className='row p-2'>
          <div className='col'>

            <div className=''>
              <div className="card mt-2">
                <div className="card-header text-center font-weight-bold fs-4">
                    Find us on MAP
                </div>
                <ul className="list-group list-group-flush">
                  <li class="list-group-item text-center">
                   
                   <div className='responsive-map'>
                   <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3559.8772839141143!2d75.56265937522187!3d26.843855176688308!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x396c4850e05bee9b%3A0x1b8d67402d4eb863!2sManipal%20University%20Jaipur!5e0!3m2!1sen!2sin!4v1718165755916!5m2!1sen!2sin"
                    width="600" height="450"  allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade">
                      </iframe>  
                  </div> 
                   
                   
                  </li>

                </ul>
              </div>
            </div>


          </div>
        </div>

            </div>
          </div>

        <div className='mt-4'>
          <Footer />
        </div>

      </div>
    </>
  )
}

export default Contact