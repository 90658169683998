import React from 'react'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import icids_logo from '../images/icids_logo.png'

function NavbarMenu() {
  return (
    <>
    
            <Navbar expand="lg" className="bg-white container-fluid fontFamily" sticky="top" style={{position:"fixed",clear:"both",margin:"0px auto",width:"100%"}}>
      <Container>
        <Navbar.Brand href="/">
          <img src={icids_logo} alt='' style={{width:"100%",height:"60px"}} />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav"  style={{marginLeft:"200px"}}>
          <Nav className="me-auto">
            <Nav.Link href="/">Home</Nav.Link>

            <NavDropdown title="Committees" id="basic-nav-dropdown">
              
              <NavDropdown.Item href="TechnicalPro">Technical Program Committee</NavDropdown.Item>
              <NavDropdown.Item href="patrons">Patrons</NavDropdown.Item>
              <NavDropdown.Item href="organizing"> Organizing Committee</NavDropdown.Item>
             
            </NavDropdown>

            <NavDropdown title="Authors" id="basic-nav-dropdown">
              <NavDropdown.Item href="callforpaper">Call For Paper</NavDropdown.Item>
              <NavDropdown.Item href="importantdate">Important Dates</NavDropdown.Item>
              <NavDropdown.Item href="papersubmission">Paper Submission</NavDropdown.Item>
              <NavDropdown.Item href="conferencetracks">Conference Tracks</NavDropdown.Item>
              
            </NavDropdown>

            <Nav.Link href="registration">Registration</Nav.Link>
            <Nav.Link href="contact">Contact</Nav.Link>
            
          </Nav>
          
        </Navbar.Collapse>
        <Nav.Link href="pp" style={{color:"white",backgroundColor: "#555555",fontSize:"20px",borderRadius:"8px",textAlign:"center",padding:"10px"}}>Poster Presentation</Nav.Link>
      </Container>
     
    </Navbar>
    
    </>
  )
}

export default NavbarMenu