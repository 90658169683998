import React from 'react'
import Conference from './Conference'
import Footer from './Footer'
import NavbarMenu from './NavbarMenu';
function Organizing() {
  return (
    <>
       <div className='container-fluid'>
           <div>
              <NavbarMenu />
           </div>
            <div className='row'>
                <div className='col'>
                    <Conference />
                </div>
            </div>
          

            <div className='row'>

<div className='row'>
    <div className='col col-11 text-center fs-4 p-2 text-wrap bgColorDemo text-darh mt-3 fontFamily' style={{margin:"0px auto"}}>
        ORGANIZING COMMITTEE 
    </div>
</div>


    <div className='row'>
      <div className='col col-8' style={{margin:"0px auto"}}>
<table class="table table-striped text-center mt-3 border">
  
  <tbody>
    <tr>
      <td>
        <span style={{fontSize:"18px"}}>Dr. Neha V Sharma<br />
        <span style={{fontSize:"14px"}}>Assistant Professor, Department of Data Science and Engineering,Manipal University Jaipur</span></span>
      </td>
    </tr>

    <tr>
      <td>
        <span style={{fontSize:"18px"}}>Dr. Rekha Chaturvedi<br />
        <span style={{fontSize:"14px"}}>Assistant Professor, Department of Data Science and Engineering,Manipal University Jaipur</span></span>
      </td>
    </tr>
    <tr>
      <td>
        <span style={{fontSize:"18px"}}>Dr. Sudhir Sharma<br />
        <span style={{fontSize:"14px"}}>Assistant Professor, Department of Data Science and Engineering,Manipal University Jaipur</span></span>
      </td>
    </tr>
    <tr>
      <td>
        <span style={{fontSize:"18px"}}>Dr. Ginika Mahajan<br />
        <span style={{fontSize:"14px"}}>Assistant Professor, Department of Data Science and Engineering,Manipal University Jaipur</span></span>
      </td>
    </tr>
   
    <tr>
      <td>
        <span style={{fontSize:"18px"}}>Mr. Deevesh Chaudhary<br />
        <span style={{fontSize:"14px"}}>Assistant Professor, Department of Data Science and Engineering,Manipal University Jaipur</span></span>
      </td>
    </tr>
    <tr>
      <td>
        <span style={{fontSize:"18px"}}>Dr. Shweta Redkar<br />
        <span style={{fontSize:"14px"}}>Assistant Professor, Department of Data Science and Engineering,Manipal University Jaipur
        </span></span>
      </td>
    </tr>
    <tr>
      <td>
        <span style={{fontSize:"18px"}}>Ms. Meenakshi Gaur<br />
        <span style={{fontSize:"14px"}}>Assistant Professor, Department of Data Science and Engineering,Manipal University Jaipur</span></span>
      </td>
    </tr>
    <tr>
      <td>
        <span style={{fontSize:"18px"}}>Dr. Anju George<br />
        <span style={{fontSize:"14px"}}>Assistant Professor, Department of Data Science and Engineering,Manipal University Jaipur</span></span>
      </td>
    </tr>
    <tr>
      <td>
        <span style={{fontSize:"18px"}}>Dr. Neeraj Kumar Verma<br />
        <span style={{fontSize:"14px"}}>Assistant Professor, Department of Data Science and Engineering,Manipal University Jaipur</span></span>
      </td>
    </tr>
    <tr>
      <td>
        <span style={{fontSize:"18px"}}>Dr. Malvinder Singh Bali<br />
        <span style={{fontSize:"14px"}}>Assistant Professor, Department of Data Science and Engineering,Manipal University Jaipur</span></span>
      </td>
    </tr>
    <tr>
      <td>
        <span style={{fontSize:"18px"}}>Dr. Srikanta<br />
        <span style={{fontSize:"14px"}}>Assistant Professor, Department of Data Science and Engineering,Manipal University Jaipur
        </span></span>
      </td>
    </tr>
    <tr>
      <td>
        <span style={{fontSize:"18px"}}>Anil Prajapati<br />
        <span style={{fontSize:"14px"}}>Assistant Professor, Department of Data Science and Engineering,Manipal University Jaipur
        </span></span>
      </td>
    </tr>
    
  </tbody>
</table>
</div>
    </div>
</div>

<div>
  <Footer />
</div>
</div>
    </>
  )
}

export default Organizing