import React from 'react'
import NavbarMenu from './NavbarMenu'
import { Link } from 'react-router-dom'
import {exbro} from '../images/brochure.jpg'
import {exbro1} from '../images/brochure1.pptx'

function PaperPre() {
  return (
    <>
    <div className='container-fluid'>
        <div>
          <NavbarMenu />
        </div>
        <div className='row' style={{marginTop:"90px"}}>
            <div className='col col-10 p-2 mb-4' style={{ margin: "0px auto" }}>

            <div>
    <h1 className='col col-12 text-center fs-4 p-2 bgColorDemo text-wrap  text-dark' style={{marginTop:"30px"}}>Guidelines for Poster Presentation for Students</h1>
<br />
A poster presentation is an effective way to visually communicate your research findings, ideas, or 
project outcomes. The following guidelines will help you create a professional, well-organized poster that conveys your work clearly and effectively.<br /><br />
<h5>1. Poster Structure (Original)</h5>
<p style={{marginLeft:"10px"}}>
<p><b>Your poster should include the following key sections :</b></p>
  <b>Title :</b> A concise and informative title that reflects the content of your work.<br />
    <p style={{marginLeft:"10px"}}>
       ➤ Use large font (72-100pt) so it can be read from a distance.<br />
       ➤ Include your name(s), affiliation (university/department), and contact information below the title.
    </p>
    
    <b>Introduction/Abstract :</b> Provide background information and the purpose of the research or project. Keep it brief and clear.<br />
    <p style={{marginLeft:"10px"}}>
       ➤ Mention the research question or objective in a few sentences.
    </p>

	<b>Methods/Materials :</b> Clearly describe how the research was conducted or what methods were used to gather data.<br />
    <p style={{marginLeft:"10px"}}>
       ➤ Use bullet points, short paragraphs, and diagrams to summarize this section.
    </p>
    <b>Results :</b> Present your findings using graphs, tables, or images.
    <p style={{marginLeft:"10px"}}>
       ➤ Keep the data visual and interpretative. Include captions to explain charts or images.
    </p>
    <b>Discussion/Conclusion :</b> Summarize your results and their significance. Discuss how your research contributes to the field.
    <p style={{marginLeft:"10px"}}>
       ➤ Highlight key takeaways and implications.
    </p>
    <b>References :</b> Cite any sources or literature you referenced in your work.
    <p></p>
    <b>Acknowledgement :</b>
    <p></p>
</p>
<h5>2. Design Guidelines</h5>
<p style={{marginLeft:"10px"}}>
<b>Layout :</b> Organize your content in columns or sections for easy flow.
<p style={{marginLeft:"10px"}}>
    ➤ Arrange the poster logically, with the introduction at the top, followed by methods, results, and conclusion.<br />
    ➤ Ensure your poster is visually balanced, not overcrowded.
</p>
<b>Font Size :</b>
<p style={{marginLeft:"10px"}}>
    ➤ Title: 72-100pt<br />
    ➤ Section headings: 36-48pt<br />
    ➤ Body text: 24-32pt<br />
    ➤ Avoid using too many font styles; stick to one or two complementary fonts.<br />
</p>
<b>Images and Graphics :</b> Use high-quality images, graphs, and diagrams.
<p style={{marginLeft:"10px"}}>
    ➤ Ensure all visuals are properly labeled and easy to understand.<br />
    ➤ Graphs should have clearly marked axes, legends, and titles.<br />
</p>
<b>Color Scheme :</b> Choose a simple and consistent color scheme.
<p style={{marginLeft:"10px"}}>
    ➤ Use contrasting colors for background and text to ensure readability.<br />
    ➤ Avoid overly bright or clashing colors.<br />
</p>
</p>
<h5>3. Content Tips</h5>
  <p style={{marginLeft:"10px"}}>
    <b>Conciseness :</b><br />
    <p style={{marginLeft:"10px"}}>
      ➤ Be clear and concise. Avoid overloading the poster with too much text. Keep sentences short and to the point.<br />
    </p>
    <b>Bullet Points :</b><br />
    <p style={{marginLeft:"10px"}}>
      ➤ Use bullet points to present key ideas and break up large blocks of text.
    </p>
    <b>Clarity :</b><br />
    <p style={{marginLeft:"10px"}}>
      ➤ Ensure the research question, methods, and findings are easy to understand, even for someone outside your field of study.
    </p>
    <b>Captions :</b><br />
    <p style={{marginLeft:"10px"}}>
      ➤ Add brief captions to figures, tables, and graphs to provide context.
    </p>
  </p>
  <h5>4. Presentation Tips</h5>
  <p style={{marginLeft:"10px"}}>
   <b>Engagement :</b> Be prepared to explain your poster to viewers. Practice a brief (2-3 minute) summary of your work.<br />
   <p style={{marginLeft:"10px"}}>
     ➤ Be ready for questions and tailor your responses to the audience's level of expertise.
   </p>
   <b>Confidence :</b><br />
   <p style={{marginLeft:"10px"}}>
     ➤ Speak clearly and maintain eye contact. Show enthusiasm for your work.
   </p>
   <b>Interaction :</b><br />
   <p style={{marginLeft:"10px"}}>
     ➤ Encourage interaction by inviting questions and discussions. Bring business cards or handouts with additional information if possible.
   </p>  
  </p>
<h5>5. Size and Format</h5>
<p style={{marginLeft:"10px"}}>
  <p style={{marginLeft:"10px"}}>
     ➤ The poster size will typically be provided by the event organizer (common sizes include 36x48 inches or A0).<br />
     ➤ Prepare your poster using a program like PowerPoint, Adobe Illustrator, or Canva.<br />
     ➤ Print your poster in high resolution (300 dpi or higher) to ensure clarity.
  </p>
</p>
<h5>6. Time Management</h5>
<p style={{marginLeft:"10px"}}>
  <p style={{marginLeft:"10px"}}>
     ➤ Plan ahead and start working on your poster early.<br />
     ➤ Proofread your poster carefully for grammar and formatting errors.<br />
     ➤ Allow time for revisions based on feedback from peers or mentors.
</p>
</p>
<h5>7. Final Checklist:</h5>
<p style={{marginLeft:"10px"}}>
  <p style={{marginLeft:"10px"}}>
   ➤ Is the title clear and easily readable from a distance ?<br />
   ➤ Are the key sections (Introduction, Methods, Results, Conclusion) present and logically organized and Original?<br />
   ➤ Are the fonts and colors chosen for readability?<br />
   ➤ Do the visuals (graphs, images, etc.) enhance the understanding of the content?<br />
   ➤ Have you rehearsed explaining your poster?<br />
  </p>
</p>
Following these guidelines will help you create a visually appealing, clear, and informative poster presentation that effectively communicates your research.<br />
<br />
<div className='row' style={{textAlign:"center"}}>
<dvi className="col col-12">
<a className="btn btn-danger col col-5" href={require("../images/brochure.jpg")} download="Brochure">Download Brochure</a>&nbsp;&nbsp;
<a className="btn btn-danger col col-5" href={require("../images/brochure1.pptx")} download="Paper_Format">Download Paper Format</a>
</dvi>
</div>
<p style={{textAlign:"center"}}>For any query you can contact us at :<br /><b> poster.competition.muj@gmail.com<br />
Dr. Pooja Gupta, Dr. Shweta Redkar, Dr. Rekha Chaturvedi</b></p>
</div>

            </div>
        </div>
    
    </div>
    </>
  )
}

export default PaperPre