import React from 'react'
import Conference from './Conference'
import Footer from './Footer'
import NavbarMenu from './NavbarMenu';
function ImportantDate() {
  return (
    <>
            <div className='container-fluid'>
            <div className='row'>
              <div>
                <NavbarMenu />
              </div>
                <div className='col'>
                    <Conference />
                </div>
            </div>

            <div className='row'>
            <div className='col col-11 text-center fs-4 p-2 bgColorDemo text-wrap  text-dark mt-3' style={{margin:"0px auto"}}>
              Important Date 
    </div>
</div>


    <div className='row'>
      <div className='col col-10' style={{margin:"0px auto"}}>
<table class="table table-striped text-center mt-3 border">
  
  <tbody>
    <tr>
      <td>
          Full Paper Submission by
      </td>
      <td>
      <span style={{textDecorationLine:"line-through"}}>12th October 2024</span><br />
      22<sup>nd</sup> October 2024
      </td>
    </tr>
    <tr>
      <td>
      Decision
      </td>
      <td>
      Within 30 Days of Paper Submission
      </td>
    </tr>
    <tr>
      <td>
      Registration Deadline
      </td>
      <td>
      10th November 2024
      </td>
    </tr>
    <tr>
      <td>
      Conference Date
      </td>
      <td>
      28<sup>th</sup> and 29<sup>th</sup> November 2024
      </td>
    </tr>
    
  </tbody>
</table>
</div>
    </div>
            <div className='mt-4'>
                <Footer />
            </div>
        </div>
    </>
  )
}

export default ImportantDate