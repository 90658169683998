import React from 'react'
import Conference from './Conference'
import Footer from './Footer'
import NavbarMenu from './NavbarMenu';

function TechnicalPro() {
  return (
    <>
         <div className='container-fluid'>
           <div>
              <NavbarMenu />
           </div>
            <div className='row'>
                <div className='col'>
                    <Conference />
                </div>
            </div>
          

            <div className='row'>

<div className='row'>
    <div className='col col-11 text-center fs-4 p-2 text-wrap bgColorDemo text-darh mt-3 fontFamily' style={{margin:"0px auto"}}>
        Advisory Committee	
    </div>
</div>


    <div className='row'>
      <div className='col col-8' style={{margin:"0px auto"}}>
<table className="table table-striped text-center mt-3 border">
  
  <tbody className=''>
  <tr>
      <td col col-4>
        <span style={{fontSize:"18px"}}>Prof.(Dr.) A. S. Vidyarthi<br />
        <span style={{fontSize:"14px"}}>Vice Chancellor, Bikaner Technical University, India

        </span></span>
      </td>
    
      <td col col-4>
        <span style={{fontSize:"18px"}}>Prof.(Dr.) Ashutosh Kumar Singh
        <br />
        <span style={{fontSize:"14px"}}>Indian Institute of Information Technology (IIIT), Bhopal, India

        </span></span>
      </td>
    </tr>

    <tr>
      <td col col-4>
        <span style={{fontSize:"18px"}}>Prof.(Dr.) Brahmjit Singh 
        <br />
        <span style={{fontSize:"14px"}}>National Institute of Technology (NIT), Kurukshetra, India

        </span></span>
      </td>
    
      <td col col-4>
        <span style={{fontSize:"18px"}}>Dr. D.S. Chauhan 

        <br />
        <span style={{fontSize:"14px"}}>PRO-CHANCELLOR, GLA MATHURA, INDIA

        </span></span>
      </td>
    </tr>


    <tr>
      <td col col-4>
        <span style={{fontSize:"18px"}}>Prof. Dharam Singh <br />
        <span style={{fontSize:"14px"}}>Namibia University Of Science And Technology, Namibia
        </span></span>
      </td>
    
      <td col col-4>
        <span style={{fontSize:"18px"}}>Prof.(Dr.) G.S Tomar 
        <br />
        <span style={{fontSize:"14px"}}>Director, Rajkiya Engineering College,Sonbhadra,Uttar Pradesh, India

        </span></span>
      </td>

    </tr>

    <tr>
      <td col col-4>
        <span style={{fontSize:"18px"}}>Dr. Gagangeet Aujla 

        <br />
        <span style={{fontSize:"14px"}}>Department of Computer Science, Durham University, UK

        </span></span>
      </td>
    
      <td col col-4>
        <span style={{fontSize:"18px"}}>Dr. Gauri Mittal  

        <br />
        <span style={{fontSize:"14px"}}>Professor, College of Engineering and Physical Sciences, Guelph, Ontario, Canada

        </span></span>
      </td>
    </tr>

    <tr>
    <td col col-4>
        <span style={{fontSize:"18px"}}>Dr Hare Ram Shah

        <br />
        <span style={{fontSize:"14px"}}>SAGE University, Indore, India

        </span></span>
      </td>
      <td col col-4>
        <span style={{fontSize:"18px"}}>Dr. Heena Rathore 
        <br />
        <span style={{fontSize:"14px"}}>Texas State University, New Braunfels, Texas, USA
        </span></span>
      </td>
    
     
    </tr>
    <tr>
    <td col col-4>
        <span style={{fontSize:"18px"}}>Dr Jasleen Kaur 
        <br />
        <span style={{fontSize:"14px"}}>University of Saskatchewan, Saskatoon, Saskatchewan, Canada
        </span></span>
      </td>
      <td col col-4>
        <span style={{fontSize:"18px"}}>Dr. Jay Alonzo Abaleta
        <br />
        <span style={{fontSize:"14px"}}>Adamson University, Philippines
        </span></span>
      </td>
    
    </tr>
    <tr>
    <td col col-4>
        <span style={{fontSize:"18px"}}>Prof. Jiban Shrestha 

        <br />
        <span style={{fontSize:"14px"}}>Scientist at Nepal Agricultural Research Council, Nepal

        </span></span>
      </td>
    <td col col-4>
        <span style={{fontSize:"18px"}}>Dr Khyati Marwah 
        <br />
        <span style={{fontSize:"14px"}}>Westford University College, Dubai, United Arab Emirates
        </span></span>
      </td>
    </tr>

    <tr>
    <td col col-4>
        <span style={{fontSize:"18px"}}>Dr. Marcin Paprzycki 
        <br />
        <span style={{fontSize:"14px"}}>Polish Academy of Sciences Systems Research Institute Warsaw, Poland
        </span></span>
      </td>
      <td col col-4>
        <span style={{fontSize:"18px"}}>Prof. Nalin Asanaka 
        <br />
        <span style={{fontSize:"14px"}}>Computer Science and Information Technology, La Trobe University, Australia
        </span></span>
      </td>
    
    </tr>

    <tr>
    <td col col-4>
        <span style={{fontSize:"18px"}}>Dr. Rahul Singh 

        <br />
        <span style={{fontSize:"14px"}}>The University of Hong Kong (HKU)
        </span></span>
      </td>
      <td col col-4>
        <span style={{fontSize:"18px"}}>Dr. Rajeev Goyal 

        <br />
        <span style={{fontSize:"14px"}}>Amity University Gwalior, India

        </span></span>
      </td>
    </tr>

    <tr>
    <td col col-4>
        <span style={{fontSize:"18px"}}>Dr. Rajkumar Buyya 

        <br />
        <span style={{fontSize:"14px"}}>School of Computing and Information Systems, The Uni of Melbourne, Australia

        </span></span>
      </td>
      <td col col-4>
        <span style={{fontSize:"18px"}}>Dr Sandeep Kaur Kuttal
        <br />
        <span style={{fontSize:"14px"}}>North Carolina State University, Raleigh, North Carolina, United States
        </span></span>
      </td>
  
    </tr>
    <tr>
    <td col col-4>
        <span style={{fontSize:"18px"}}>Dr. Sandeep Sahu 
        <br />
        <span style={{fontSize:"14px"}}>Lakshmi Narain College of Technology, Bhopal, MP, India
        </span></span>
      </td>
      <td colSpan={2}>
        <span style={{fontSize:"18px"}}>Dr. Vishal Bharti 
        <br />
        <span style={{fontSize:"14px"}}>Maharishi Markandeshwar, Mullana-Ambala, Haryana, India
        </span></span>
      </td>
    </tr>
  </tbody>
</table>
</div>
    </div>

    

</div>

<div className='row'>

<div className='row'>
    <div className='col col-11 text-center fs-4 p-2 text-wrap bgColorDemo text-darh mt-3 fontFamily' style={{margin:"0px auto"}}>
    Technical Program Committee	
    </div>
</div>


    <div className='row'>
      <div className='col col-8' style={{margin:"0px auto"}}>
<table class="table table-striped text-center mt-3 border">
  
  <tbody>

    <tr>
      <td col col-4>
        <span style={{fontSize:"18px"}}>Dr. Abhay Kumar Agarwal
        <br />
        <span style={{fontSize:"14px"}}>KNIT Sultanpur, India

        </span></span>
      </td>
    
      <td col col-4>
        <span style={{fontSize:"18px"}}>Dr. Abhishek Tipathi 
        <br />
        <span style={{fontSize:"14px"}}>MITS Gwalior, India

        </span></span>
      </td>
    </tr>

    <tr>
      <td col col-4>
        <span style={{fontSize:"18px"}}>Dr. Aditya K Saxena
        <br />
        <span style={{fontSize:"14px"}}>SCSE, Galgotias University, U.P.
        </span></span>
      </td>
    
      <td col col-4>
        <span style={{fontSize:"18px"}}>Prof. Aditya Trivedi  
        <br />
        <span style={{fontSize:"14px"}}>IIITM,Gwalior, India
        </span></span>
      </td>
    </tr>

    <tr>
      <td col col-4>
        <span style={{fontSize:"18px"}}>Dr. Ajay Kumar 
        <br />
        <span style={{fontSize:"14px"}}>NIIT (China) Limited, Shanghai, Guizhou Normal University, Guiyang, Guizhou Province, China

        </span></span>
      </td>
    
      <td col col-4>
        <span style={{fontSize:"18px"}}>Dr. Akhilendra Pratap Singh
        <br />
        <span style={{fontSize:"14px"}}>National Institute of Technology Meghalaya, India
        </span></span>
      </td>
    </tr>
    <tr>
    <td col col-4>
        <span style={{fontSize:"18px"}}>Dr. Amit Ojha  

        <br />
        <span style={{fontSize:"14px"}}>MANIT, Bhopal , India
        </span></span>
      </td>
      <td col col-4>
        <span style={{fontSize:"18px"}}>Dr. Anand Sharma 
        <br />
        <span style={{fontSize:"14px"}}>Motilal Nehru National Institute of Technology Allahabad, India

        </span></span>
      </td>
    
    </tr>
    <tr>
    <td col col-4>
        <span style={{fontSize:"18px"}}>Dr. Anchit Bijalwan 
        <br />
        <span style={{fontSize:"14px"}}>"Lead Computing, Games and Data Science Academic Research Cluster 
          British University Vietnam"
        </span></span>
      </td>
      <td col col-4>
        <span style={{fontSize:"18px"}}>Dr. Aresh Dadlani  

        <br />
        <span style={{fontSize:"14px"}}>University of Tehran, Iran

        </span></span>
      </td>
      
    </tr>
    <tr>
    <td col col-4>
        <span style={{fontSize:"18px"}}>Dr. Arul Kumar
        <br />
        <span style={{fontSize:"14px"}}>SSN College of Engineering, Tamilnadu, India
        </span></span>
      </td>
    
      <td col col-4>
        <span style={{fontSize:"18px"}}>Dr. Arvind Kumar Tiwari
        <br />
        <span style={{fontSize:"14px"}}>Kamla Nehru Institute of Technology (KNIT), Sultanpur (Uttar Pradesh), India
        </span></span>
      </td>
    </tr>
    <tr>
      <td col col-4>
        <span style={{fontSize:"18px"}}>Dr. Ashish Mishra 
        <br />
        <span style={{fontSize:"14px"}}>Gyan Ganga Institute of Technology and Sciences, Jabalpur, MP, India
        </span></span>
      </td>
      <td col col-4>
        <span style={{fontSize:"18px"}}>Dr. Ashish Bagwari  

        <br />
        <span style={{fontSize:"14px"}}>WIT,Dehradun, India

        </span></span>
      </td>
    
    </tr>
    <tr>
      <td col col-4>
        <span style={{fontSize:"18px"}}>Dr. Ashish Ranjan  

        <br />
        <span style={{fontSize:"14px"}}>National Institute of Technology (NIT), Manipur, India

        </span></span>
      </td>
      <td col col-4>
        <span style={{fontSize:"18px"}}>Dr. Ashish Gupta  
        <br />
        <span style={{fontSize:"14px"}}>Dr. Shyama Prasad Mukherjee University, Ranchi, India
        </span></span>
      </td>
    
    </tr>
    <tr>
      <td col col-4>
        <span style={{fontSize:"18px"}}>Dr. Ashraf Samarah  
        <br />
        <span style={{fontSize:"14px"}}>Al Balqa Applied University, University in Salt, Jordan

        </span></span>
      </td>
   
      <td col col-4>
        <span style={{fontSize:"18px"}}>Prof. Ashutosh Kumar Singh 

        <br />
        <span style={{fontSize:"14px"}}>NIT, Kurukshetra, India
        </span></span>
      </td>
    </tr>
    <tr>
    <td col col-4>
        <span style={{fontSize:"18px"}}>Dr. Banalaxmi Brahma  

        <br />
        <span style={{fontSize:"14px"}}>NIT Jalandhar
        </span></span>
      </td>
      <td col col-4>
        <span style={{fontSize:"18px"}}>Dr. Bharat Singh Deora
        <br />
        <span style={{fontSize:"14px"}}>JRNRV University, Udaipur, India
        </span></span>
      </td>
    </tr>
    <tr>
   
      <td col col-4>
        <span style={{fontSize:"18px"}}>Dr. Bharti Nagpal  
        <br />
        <span style={{fontSize:"14px"}}>Netaji Subhas University of Technology (East Campus), India
        </span></span>
      </td>
      <td col col-4>
        <span style={{fontSize:"18px"}}>Dr. Bhola Jha  

        <br />
        <span style={{fontSize:"14px"}}>GBPIET, India

        </span></span>
      </td>
    </tr>
    <tr>
  
      <td col col-4>
        <span style={{fontSize:"18px"}}>Dr. Biju Paul  
        <br />
        <span style={{fontSize:"14px"}}>Rajagiri School of Engineering and Technology, Kochi, Kerala
        </span></span>
      </td>
      <td col col-4>
        <span style={{fontSize:"18px"}}>Dr. Brajesh Kumar Kaushik
        <br />
        <span style={{fontSize:"14px"}}>IIT Roorkee ,India
        </span></span>
      </td>

    </tr>
    <tr>
    
      <td col col-4>
        <span style={{fontSize:"18px"}}>Dr. Ciro Rodriguez  
        <br />
        <span style={{fontSize:"14px"}}>National University Mayor de San Marcos, Lima, Peru
        </span></span>
      </td>
      <td col col-4>
        <span style={{fontSize:"18px"}}>Dr. Deepak Tomar  

        <br />
        <span style={{fontSize:"14px"}}>NIT, Bhopal, India

        </span></span>
      </td>
    </tr>
    <tr>
      <td col col-4>
        <span style={{fontSize:"18px"}}>Dr. Deepak Jain  
  
        <br />
        <span style={{fontSize:"14px"}}>Chongqing University of Posts and Telecommunications, Chongqing, China

        </span></span>
      </td>
      <td col col-4>
        <span style={{fontSize:"18px"}}>Prof. Dharmendra Singh  
        <br />
        <span style={{fontSize:"14px"}}>IIT Roorkee
        </span></span>
      </td>
    </tr>
    <tr>
  
      <td col col-4>
        <span style={{fontSize:"18px"}}>Dr. Dilbag Singh 
        <br />
        <span style={{fontSize:"14px"}}>New York University Grossman School of Medicine, USA
        </span></span>
      </td>
      <td col col-4>
        <span style={{fontSize:"18px"}}>Prof. Diptendu Sinha Roy
        <br />
        <span style={{fontSize:"14px"}}>National Institute of Technology Meghalaya, India
        </span></span>
      </td>
    </tr>
    <tr>
      <td col col-4>
        <span style={{fontSize:"18px"}}>Prof. Fahreddin Sadıkoğlu  

        <br />
        <span style={{fontSize:"14px"}}>Near East University, Northern Cyprus

        </span></span>
      </td>
      <td col col-4>
        <span style={{fontSize:"18px"}}>Dr. Ganpat Joshi 
        <br />
        <span style={{fontSize:"14px"}}>Madhav university Abu Road Sirohi Rajasthan, India
        </span></span>
      </td>
    </tr>
    <tr>
      
      <td col col-4>
        <span style={{fontSize:"18px"}}>Dr. Gaurav Kumar Ameta
        <br />
        <span style={{fontSize:"14px"}}>Parul University, Vadodara, Gujarat, India
        </span></span>
      </td>
      <td col col-4>
        <span style={{fontSize:"18px"}}>Maj. Gen. Dr. S.C.
        <br />
        <span style={{fontSize:"14px"}}>Amity University, Gurugram, India
        </span></span>
      </td>
    </tr>
    <tr>
      <td col col-4>
        <span style={{fontSize:"18px"}}>Prof. Hari Prabhat Gupta 
        <br />
        <span style={{fontSize:"14px"}}>IIT,BHU, India

        </span></span>
      </td>
      <td col col-4>
       <span style={{fontSize:"18px"}}>Dr. Harish Kumar Shakya 
       <br />
       <span style={{fontSize:"14px"}}>Associate Professor, Manipal University Jaipur
       </span></span>
     </td>
    </tr>
    <tr>
     <td col col-4>
       <span style={{fontSize:"18px"}}>Dr. Hemant Dhabhai  
       <br />
       <span style={{fontSize:"14px"}}>Aravali Institute of Technical Studies, Udaipur
       </span></span>
     </td>
     <td col col-4>
       <span style={{fontSize:"18px"}}>Dr. Hemant Kumar Singhal 
       <br />
       <span style={{fontSize:"14px"}}>National Institute of Technology (NIT), Uttarakhand, India
       </span></span>
     </td>
   </tr>
   <tr>
     <td col col-4>
       <span style={{fontSize:"18px"}}>Dr. Hon Tat Hui, 
       <br />
       <span style={{fontSize:"14px"}}>NUS, Singapore
       </span></span>
     </td>
     <td col col-4>
       <span style={{fontSize:"18px"}}>Dr. Indhumathi K  

       <br />
       <span style={{fontSize:"14px"}}>Saveetha Engineering College, Chennai, India
       </span></span>
     </td>
   </tr>
   <tr>
     <td col col-4>
        <span style={{fontSize:"18px"}}>Dr Jitendra Singh Chauhan
        <br />
        <span style={{fontSize:"14px"}}>GITS, Udaipur, India
        </span></span>
      </td>
      <td col col-4>
        <span style={{fontSize:"18px"}}>Dr Jitendra Singh Thakur
        <br />
        <span style={{fontSize:"14px"}}>Govt. Engineering College, Jabalpur, India

        </span></span>
      </td>
   </tr>
    <tr>
      <td col col-4>
        <span style={{fontSize:"18px"}}>Dr. Jitendra Agrawal  
        <br />
        <span style={{fontSize:"14px"}}>RGPV Bhopal, India
        </span></span>
      </td>
      <td col col-4>
        <span style={{fontSize:"18px"}}>Dr. Jorge Luis Victória Barbosa
        <br />
        <span style={{fontSize:"14px"}}>University of Vale do Rio dos Sinos, Unisinos, Brazil
        </span></span>
      </td>
    </tr>
    <tr>
   
      <td col col-4>
        <span style={{fontSize:"18px"}}>Dr. Jubilant J Kizhakkethottam
        <br />
        <span style={{fontSize:"14px"}}>Saintgits College of Engineering, Kottayam, Kerala, India

        </span></span>
      </td>
      <td col col-4>
        <span style={{fontSize:"18px"}}>Dr. Juned Ahmed Siddiqui 
        <br />
        <span style={{fontSize:"14px"}}>Medi-Caps University, Indore, India

        </span></span>
      </td>
    </tr>
    <tr>
      <td col col-4>
        <span style={{fontSize:"18px"}}>Dr. Ranjeet K Ranjan  

        <br />
        <span style={{fontSize:"14px"}}>Thapar Institute of Engineering and Technology
        </span></span>
      </td>
      <td col col-4>
        <span style={{fontSize:"18px"}}>Dr. Kalpana Jain  
        <br />
        <span style={{fontSize:"14px"}}>CTAE, Udaipur
        </span></span>
      </td>
    </tr>
    <tr>
      <td col col-4>
        <span style={{fontSize:"18px"}}>Prof. Musti Kameswara Sastry  
        <br />
        <span style={{fontSize:"14px"}}>Namibia University
        </span></span>
      </td>
      <td col col-4>
        <span style={{fontSize:"18px"}}>Dr. Kiran Kumar Pattanaik 
        <br />
        <span style={{fontSize:"14px"}}>IIITM, Gwalior, India
        </span></span>
      </td>
    </tr>
    <tr>
    
      <td col col-4>
        <span style={{fontSize:"18px"}}>Dr. Hakan Koyuncu
        <br />
        <span style={{fontSize:"14px"}}>Altinbas University (Istanbul) , Turkey
        </span></span>
      </td>
      <td col col-4>
        <span style={{fontSize:"18px"}}>Dr. Krishan Kumar  
        <br />
        <span style={{fontSize:"14px"}}>National Institute of Technology (NIT), Uttarakhand, India
        </span></span>
      </td>
    </tr>
    <tr>
   
      <td col col-4>
        <span style={{fontSize:"18px"}}>Dr. Kshitij Pathak  
        <br />
        <span style={{fontSize:"14px"}}>Government Polytechnic College Mandsaur
        </span></span>
      </td>
      <td col col-4>
        <span style={{fontSize:"18px"}}>Dr. Lalit Garg  
        <br />
        <span style={{fontSize:"14px"}}>Malta University, Malta
        </span></span>
      </td>
    </tr>
    <tr>
     
   
      <td col col-4>
        <span style={{fontSize:"18px"}}>Dr. Manikandan K  
        <br />
        <span style={{fontSize:"14px"}}>Vellore Institute of Technology (VIT), Vellore, India
        </span></span>
      </td>
      <td col col-4>
        <span style={{fontSize:"18px"}}>Dr. Manish Dixit  

        <br />
        <span style={{fontSize:"14px"}}>MITS, Gwalior, India
        </span></span>
      </td>
    </tr>
    <tr>
      <td col col-4>
        <span style={{fontSize:"18px"}}>Dr. Manju Mandot  
        <br />
        <span style={{fontSize:"14px"}}>CSI, Udaipur
        </span></span>
      </td>
      <td col col-4>
        <span style={{fontSize:"18px"}}>Dr. Manoj Diwakar  
        <br />
        <span style={{fontSize:"14px"}}>Associate Professor, Graphic Era University Dehradun
        </span></span>
      </td>
    </tr>
    <tr>
      <td col col-4>
        <span style={{fontSize:"18px"}}>Dr. Mayank Patel  
        <br />
        <span style={{fontSize:"14px"}}>Geetanjali Institute of Technical Studies, Udaipur
        </span></span>
      </td>
      <td col col-4>
        <span style={{fontSize:"18px"}}>Dr. Mayank Swarnkar  
        <br />
        <span style={{fontSize:"14px"}}>Indian Institute of Technology (IIT)-BHU, Varanasi
        </span></span>
      </td>
    </tr>
    <tr>
      <td col col-4>
        <span style={{fontSize:"18px"}}>Dr. Meena Tiwari  
        <br />
        <span style={{fontSize:"14px"}}>SRIT, Jabalpur
        </span></span>
      </td>
      <td col col-4>
        <span style={{fontSize:"18px"}}>Dr. Meena Jha  
        <br />
        <span style={{fontSize:"14px"}}>School of Engineering and Technology, Central Queensland University, Australia
        </span></span>
      </td>
    </tr>
    <tr>
      <td col col-4>
        <span style={{fontSize:"18px"}}>Prof. Michał Jasiński  
        <br />
        <span style={{fontSize:"14px"}}>Wroclaw University of Science and Technology, Poland
        </span></span>
      </td>
      <td col col-4>
        <span style={{fontSize:"18px"}}>Prof. Milan Tuba  
        <br />
        <span style={{fontSize:"14px"}}>Singidunum University, belgrade
        </span></span>
      </td>
    </tr>
    <tr>
   
      <td col col-4>
        <span style={{fontSize:"18px"}}>Mr. Mohammad Firdos Sheikh
        <br />
        <span style={{fontSize:"14px"}}>Poornima University, Jaipur, Rajasthan, India
        </span></span>
      </td>
      <td col col-4>
        <span style={{fontSize:"18px"}}>Dr. Mohammad Kamrul Hasan 
        <br />
        <span style={{fontSize:"14px"}}>Universiti Kebangsaan Malaysia, Malaysia
        </span></span>
      </td>
    </tr>

    <tr>
     
   
      <td col col-4>
        <span style={{fontSize:"18px"}}>Dr. Mohit Mittal  
        <br />
        <span style={{fontSize:"14px"}}>National Institute for Research in Computer Science and Automation (INRIA), France
        </span></span>
      </td>
      <td col col-4>
        <span style={{fontSize:"18px"}}>Dr. Mukesh Kumar  
        <br />
        <span style={{fontSize:"14px"}}>National Institute of Technology (NIT) Kurukshetra, India
        </span></span>
      </td>
    </tr>

    <tr>
      <td col col-4>
        <span style={{fontSize:"18px"}}>Dr. N.Ch.Sriman Narayana Iyengar 
        <br />
        <span style={{fontSize:"14px"}}>VIT Vellore, India
        </span></span>
      </td>
      <td col col-4>
        <span style={{fontSize:"18px"}}>Prof. N.S Chaudhary  
        <br />
        <span style={{fontSize:"14px"}}>IIT, Indore, India
        </span></span>
      </td>
    </tr>
    <tr>
      <td col col-4>
        <span style={{fontSize:"18px"}}>Dr. Nalika Ulapane  
        <br />
        <span style={{fontSize:"14px"}}>La Trobe University, School of Computing, Engineering & Mathematical Sciences, Melbourne, Australia
        </span></span>
      </td>
      <td col col-4>
        <span style={{fontSize:"18px"}}>Dr. Naveen Chilamkurti 
        <br />
        <span style={{fontSize:"14px"}}>La Trobe University, Melbourne, Australia

        </span></span>
      </td>
    </tr>

    <tr>
      <td col col-4>
        <span style={{fontSize:"18px"}}>Prof. Neelendra Badal 
        <br />
        <span style={{fontSize:"14px"}}>REC Bijnor, India

        </span></span>
      </td>
      <td col col-4>
        <span style={{fontSize:"18px"}}>Dr. Neeraj Pandey 
        <br />
        <span style={{fontSize:"14px"}}>Graphic Era University Dehradun, India

        </span></span>
      </td>
    </tr>
    <tr>
      <td col col-4>
        <span style={{fontSize:"18px"}}>Dr. Neeraj Kumar  
        <br />
        <span style={{fontSize:"14px"}}>Thapar Institute of Engineering and Technology, India
        </span></span>
      </td>
      <td col col-4>
        <span style={{fontSize:"18px"}}>Dr. Neha Tak 
        <br />
        <span style={{fontSize:"14px"}}>BITS Pilani, Hyderabad, India

        </span></span>
      </td>
    </tr>
    <tr>
      <td col col-4>
        <span style={{fontSize:"18px"}}>Dr. Neha Agrawal  
        <br />
        <span style={{fontSize:"14px"}}>IIITM, Sricity, India
        </span></span>
      </td>
      <td col col-4>
        <span style={{fontSize:"18px"}}>Dr. Ngoc Tu Nguyen 
        <br />
        <span style={{fontSize:"14px"}}>Missouri University, USA
        </span></span>
      </td>
     
    </tr>
    <tr>
    
      <td col col-4>
        <span style={{fontSize:"18px"}}>Dr. Nitesh Dubey 
        <br />
        <span style={{fontSize:"14px"}}>Baderia Global Institute of Engineering and Management Jabalpur, MP, India

        </span></span>
      </td>
      <td col col-4>
        <span style={{fontSize:"18px"}}>Dr. Om Jee Pandey
        <br />
        <span style={{fontSize:"14px"}}>IIT BHU, India

        </span></span>
      </td>
    </tr>
    <tr>
      
      <td col col-4>
        <span style={{fontSize:"18px"}}>Dr. Paras Kothari 
        <br />
        <span style={{fontSize:"14px"}}>Geetanjali Institute of Technical Studies, Udaipur, India

        </span></span>
      </td>
      <td col col-4>
        <span style={{fontSize:"18px"}}>Dr. phayung meesad 
        <br />
        <span style={{fontSize:"14px"}}>King Mongkut's University of Technology North Bangkok (KMUTNB), Thailand

        </span></span>
      </td>
    </tr>
    <tr>
     
      <td col col-4>
        <span style={{fontSize:"18px"}}>Dr. Pinku Ranjan 
        <br />
        <span style={{fontSize:"14px"}}>ABV-IIITM, Gwalior, India

        </span></span>
      </td>
      <td col col-4>
        <span style={{fontSize:"18px"}}>Dr. Piyush Javeria 
        <br />
        <span style={{fontSize:"14px"}}>Sai Tirupati University, Rajasthan, India

        </span></span>
      </td>
    </tr>
    <tr>
   
    
      <td col col-4>
        <span style={{fontSize:"18px"}}>Dr. Piyush Kumar Shukla
        <br />
        <span style={{fontSize:"14px"}}>University Institute of Technology, RGPV, Bhopal, India

        </span></span>
      </td>
      <td col col-4>
        <span style={{fontSize:"18px"}}>Dr. Pokkuluri Kiran Sree 
        <br />
        <span style={{fontSize:"14px"}}>Shri Vishnu Engineering College for Women, Andhra Pradesh, India
        </span></span>
      </td>
    </tr>
    <tr>
   
      <td col col-4>
        <span style={{fontSize:"18px"}}>Dr. Prakash Bahrani 
        <br />
        <span style={{fontSize:"14px"}}>SITE, Nathdwara, India

        </span></span>
      </td>
      <td col col-4>
        <span style={{fontSize:"18px"}}>Dr. Praphula Kumar Jain 
        <br />
        <span style={{fontSize:"14px"}}>IIT Dhanbad, India

        </span></span>
      </td>
    </tr>
    <tr>
   
    
      <td col col-4>
        <span style={{fontSize:"18px"}}>Dr. Prashant Ranjan 
        <br />
        <span style={{fontSize:"14px"}}>University of Engineering and Management, Jaipur, India

        </span></span>
      </td>
      <td col col-4>
        <span style={{fontSize:"18px"}}>Prof. Prasun Chakrabarti 
        <br />
        <span style={{fontSize:"14px"}}>Sir Padampat Singhania University Udaipur, Rajasthan, India

        </span></span>
      </td>
    </tr>
    <tr>
    
      <td col col-4>
        <span style={{fontSize:"18px"}}>Dr. Preeti Saxena 
        <br />
        <span style={{fontSize:"14px"}}>Devi Ahilya University, Indore, India

        </span></span>
      </td>
      <td col col-4>
        <span style={{fontSize:"18px"}}>Dr. Ram Narayan Yadav 
        <br />
        <span style={{fontSize:"14px"}}>IIITR, Ahemdabad, India
        </span></span>
      </td>
    </tr>
    <tr>
      <td col col-4>
        <span style={{fontSize:"18px"}}>Dr. Ratish Agarwal 
        <br />
        <span style={{fontSize:"14px"}}>University Institute of Technology, RGPV, Bhopal, India

        </span></span>
      </td>
      <td col col-4>
        <span style={{fontSize:"18px"}}>Mr. Ratnesh Kumar Sharma
        <br />
        <span style={{fontSize:"14px"}}>University of Technology Sydney, Australia

        </span></span>
      </td>
      
    </tr>
    <tr>
   
      <td col col-4>
        <span style={{fontSize:"18px"}}>Dr. Richa Gupta  
        <br />
        <span style={{fontSize:"14px"}}>MSIT, India
        </span></span>
      </td>
      <td col col-4>
        <span style={{fontSize:"18px"}}>Dr Rimpy Bishnoi 
        <br />
        <span style={{fontSize:"14px"}}>Techno NJR Institute of Technology, Udaipur, India

        </span></span>
      </td>
    </tr>
    <tr>
      <td col col-4>
        <span style={{fontSize:"18px"}}>Dr. Ripal Ranpara  
        <br />
        <span style={{fontSize:"14px"}}>Atmiya University, Gujrat, India
        </span></span>
      </td>
      <td col col-4>
        <span style={{fontSize:"18px"}}>Dr. Ritesh Tirole 
        <br />
        <span style={{fontSize:"14px"}}>Sir Padampat Singhania University Udaipur, Rajasthan, India

        </span></span>
      </td>
    </tr>
    <tr>
   
      <td col col-4>
        <span style={{fontSize:"18px"}}>Dr. Ruchi Sharma  
        <br />
        <span style={{fontSize:"14px"}}>Bharati Vidyapeeth College of Engineering, Navi Mumbai, India
        </span></span>
      </td>
      <td col col-4>
        <span style={{fontSize:"18px"}}>Prof. S.K Sharma 
        <br />
        <span style={{fontSize:"14px"}}>Modern Institute of Technology & Research Centre, Alwar, India

        </span></span>
      </td>
    </tr>
    <tr>
    
      <td col col-4>
        <span style={{fontSize:"18px"}}>Dr. Sachin Puntambekar  
        <br />
        <span style={{fontSize:"14px"}}>Medi-Caps University, Indore, India
        </span></span>
      </td>
      <td col col-4>
        <span style={{fontSize:"18px"}}>Dr. Samta Jain Goyal
        <br />
        <span style={{fontSize:"14px"}}>Amity University Gwalior, India

        </span></span>
      </td>
    </tr>
    <tr>
    
      <td col col-4>
        <span style={{fontSize:"18px"}}>Dr. Sanjay Agal 
        <br />
        <span style={{fontSize:"14px"}}>Parul Institute of Engineering and Technology (PIET), India

        </span></span>
      </td>
      <td col col-4>
        <span style={{fontSize:"18px"}}>Prof. Sanjay Garg 
        <br />
        <span style={{fontSize:"14px"}}>Jaypee University of Engineering and Technology, MP, India

        </span></span>
      </td>
    </tr>
    <tr>
      <td col col-4>
        <span style={{fontSize:"18px"}}>Prof. Sanjay Gaur 
        <br />
        <span style={{fontSize:"14px"}}>JECRC, Jaipur, India

        </span></span>
      </td>
      <td col col-4>
        <span style={{fontSize:"18px"}}>Dr. Sanjeet Kumar Nayak	
        <br />
        <span style={{fontSize:"14px"}}>IIITDM Kancheepuram, Chennai

        </span></span>
      </td>
    </tr>
    <tr>
     
      <td col col-4>
        <span style={{fontSize:"18px"}}>Dr. Sanjeev Sharma  
        <br />
        <span style={{fontSize:"14px"}}>RGPV Bhopal,India
        </span></span>
      </td>
      <td col col-4>
        <span style={{fontSize:"18px"}}>Dr. Saurabh Khandelwal  
        <br />
        <span style={{fontSize:"14px"}}>Oxford Brookes University, UK
        </span></span>
      </td>
    </tr>
    <tr>
    
      <td col col-4>
        <span style={{fontSize:"18px"}}>Dr. Shajin Nargunam
        <br />
        <span style={{fontSize:"14px"}}>Noorul Islam Centre for Higher Education, TamilNadu, India

        </span></span>
      </td>  
      <td col col-4>
        <span style={{fontSize:"18px"}}>Dr. Sharda Prasad Patel
        <br />
        <span style={{fontSize:"14px"}}>Gyan Ganga Institute of Technology and Sciences, Jabalpur, MP, India

        </span></span>
      </td>
    </tr>
    <tr>
    
      <td col col-4>
        <span style={{fontSize:"18px"}}>Dr. Shibu K R
        <br />
        <span style={{fontSize:"14px"}}>Saintgits College of Engineering, Kottayam, Kerala, India

        </span></span>
      </td>
      <td col col-4>
        <span style={{fontSize:"18px"}}>Dr. Siddarth Sharma  
        <br />
        <span style={{fontSize:"14px"}}>IIT, Mandi, India
        </span></span>
      </td>
    </tr>
    <tr>
   
    <td col col-4>
        <span style={{fontSize:"18px"}}>Dr. Siddharth Singh Chouhan
        <br />
        <span style={{fontSize:"14px"}}>VIT Bhopal University, bhopal, Rajasthan, India

        </span></span>
      </td>
      <td col col-4>
        <span style={{fontSize:"18px"}}>Mahipal Singh Deora  
        <br />
        <span style={{fontSize:"14px"}}>BN University
        </span></span>
      </td>
    </tr>
    <tr>
   
      <td col col-4>
        <span style={{fontSize:"18px"}}>Dr. Sumit Kumar Tetarave
        <br />
        <span style={{fontSize:"14px"}}>Kalinga Institute of Industrial Technology, Bhubaneswar, Odisha, India

        </span></span>
      </td>
      <td col col-4>
        <span style={{fontSize:"18px"}}>Dr. Sumit Kalra  
        <br />
        <span style={{fontSize:"14px"}}>IIT,Jodhpur, India
        </span></span>
      </td>
    </tr>
    <tr>
   
      <td col col-4>
        <span style={{fontSize:"18px"}}>Dr. Surendra Singh  
        <br />
        <span style={{fontSize:"14px"}}>National Institute of Technology (NIT), Uttarakhand, India

        </span></span>
      </td>
      <td col col-4>
        <span style={{fontSize:"18px"}}>Dr. Tajinder Singh Arora 
        <br />
        <span style={{fontSize:"14px"}}>National Institute of Technology (NIT), Uttarakhand, India

        </span></span>
      </td>
    </tr>
    <tr>
      
      <td col col-4>
        <span style={{fontSize:"18px"}}>Dr. Trapti Jain  
        <br />
        <span style={{fontSize:"14px"}}>IIT Indore, India

        </span></span>
      </td>
      <td col col-4>
        <span style={{fontSize:"18px"}}>Dr. Umit Cali  
        <br />
        <span style={{fontSize:"14px"}}>Norwegian University of Science and Technology, Norway

        </span></span>
      </td>
    </tr>
    <tr>
      
      <td col col-4>
        <span style={{fontSize:"18px"}}>Dr. Upendra Kumar  
        <br />
        <span style={{fontSize:"14px"}}>IET, Lucknow

        </span></span>
      </td>
      <td col col-4>
        <span style={{fontSize:"18px"}}>Dr. Urjita Thakar  
        <br />
        <span style={{fontSize:"14px"}}>Shri G.S Institute of Technology and Science, 23 Park Road, Indore (MP)

        </span></span>
      </td>
    </tr>
    <tr>
      
      <td col col-4>
        <span style={{fontSize:"18px"}}>Prof. V. M. Thakkar 
        <br />
        <span style={{fontSize:"14px"}}>GBPIET, India

        </span></span>
      </td>
      <td col col-4>
        <span style={{fontSize:"18px"}}>Dr. Vandana Roy  
        <br />
        <span style={{fontSize:"14px"}}>GGITS, Jabalpur, India

        </span></span>
      </td>
    </tr>
    <tr>
      
      <td col col-4>
        <span style={{fontSize:"18px"}}>Pooja Vashisth   
        <br />
        <span style={{fontSize:"14px"}}>York University, Canada
        </span></span>
      </td>
      <td col col-4>
        <span style={{fontSize:"18px"}}>Dr.Garima Verma   
        <br />
        <span style={{fontSize:"14px"}}>School of Computer Science, DIT University Dehradun

        </span></span>
      </td>
    </tr>
    <tr>
     
      <td col col-4>
        <span style={{fontSize:"18px"}}>Dr. Vikash Rai  
        <br />
        <span style={{fontSize:"14px"}}>National Forensic Sciences University, Gandhinagar, Gujrat
        </span></span>
      </td>
      <td col col-4>
        <span style={{fontSize:"18px"}}>Dr. Vikash Singh   
        <br />
        <span style={{fontSize:"14px"}}>Manipal Institute of Technology, India

        </span></span>
      </td>
    </tr>
    <tr>
     
      <td col col-4>
        <span style={{fontSize:"18px"}}>Dr. Vinay Kumar  
        <br />
        <span style={{fontSize:"14px"}}>Motilal Nehru National Institute of Technology Allahabad
        </span></span>
      </td>
      <td col col-4>
        <span style={{fontSize:"18px"}}>Dr. Vinod Patidar 
        <br />
        <span style={{fontSize:"14px"}}>Parul University, Vadodara, Gujarat, India

        </span></span>
      </td>
    </tr>
    
    <tr>
     
      <td col col-4>
        <span style={{fontSize:"18px"}}>Dr. Vinodh P Vijayan
        <br />
        <span style={{fontSize:"14px"}}>Mangalam College of Engineering, Kottayam, Kerala, India

        </span></span>
      </td>
      <td col col-4>
        <span style={{fontSize:"18px"}}>Dr. Waralak V. Siricharoen
        <br />
        <span style={{fontSize:"14px"}}>Silpakorn University, Thailand

        </span></span>
      </td>
    </tr>

    <tr>
     
     <td col col-4>
       <span style={{fontSize:"18px"}}>Dr. Santosh Kumar Vishwakarma
       <br />
       <span style={{fontSize:"14px"}}>Ex - HoD, AIML, Manipal University Jaipur 

       </span></span>
     </td>
     <td col col-4>
       <span style={{fontSize:"18px"}}>Dr.Bibek Katiyar
       <br />
       <span style={{fontSize:"14px"}}>Symbiosis International (Deemed University) Indore

       </span></span>
     </td>
   </tr>
    
  </tbody>
</table>
</div>
    </div>
</div>

<div>
  <Footer />
</div>
</div>
      
    </>
  )
}

export default TechnicalPro