import React from 'react'
import Footer from './Footer'
import Conference from './Conference'
import NavbarMenu from './NavbarMenu'
import { Link } from 'react-router-dom'
import logospring from '../images/logospring.jfif'


function CallForPaper() {
  return (
    <>
       <div className='container-fluid fontFamily'>
        
        <div>
          <NavbarMenu />
        </div>
            <div className='row'>
                <div className='col'>
                    <Conference />
                </div>
            </div>

            <div className='row p-2 mt-4'>
                <div className='col-12'>

                    <div className="card mt-2 border border-5" style={{ margin: "0px auto" }}>
                        <div className="card-header text-center font-weight-bold fs-4">
                        Call for Papers
                        </div>

                        <div className='row'>
                            <div className='col col-12'>
                                <ul className="list-group list-group-flush">
                                    <li className="list-group-item text-justify fs-5 fontFamily">
                                    <div className='row'>
                                        <div className='col fs-5 font-weight-bold text-wrap text-dark fw-light p-3'>
                                        Original contributions from researchers describing their original, unpublished, research contribution 
                                        which is not currently under review by another conference or journal and addressing state-of-the-art 
                                        research are invited to share their work in all areas of Data Science.
                                        Still, they are not limited to the conference tracks.
                                        Prospective authors are invited to submit paper(s) not exceeding 10 pages written in A4 size (Springer format),
                                        Over length of Paper charge Rs 1200/- per page. The authors are requested to submit full papers according to
                                        Springer 
                                        Proceeding format guidelines available at <Link to="https://www.springer.com/gp/authors-editors/conference-proceedings/conference-proceedings-guidelines" > Link</Link> 
                                       
                                    </div>
                </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='row p-2 mt-4'>
                <div className='col-12'>

                    <div className="card mt-2 border border-5" style={{ margin: "0px auto" }}>
                        <div className="card-header text-center font-weight-bold fs-4">
                        Publication and Indexing 2024

                        </div>

                        <div className='row'>
                            <div className='col col-12'>
                                <ul className="list-group list-group-flush">
                                    <li className="list-group-item text-justify fs-5 fontFamily">
                                    <div className='row'>
                                        <div className='col col-2'>
                                        <img src={logospring} alt="Logo" className='img-fluid border border-2 p-2' style={{width: "200px",height:"200px"}} />
                                        </div>
                                        <div className='col col-10 fs-5 font-weight-bold text-wrap text-dark fw-light p-3'>
                                        All ICIDS 2024 accepted and presented papers will be published in a scopus indexed proceedings by Springer in the "Lecture Notes in Networks and Systems"
                                         (LNNS) series, which is indexed by SCOPUS, EI Compendex, INSPEC, WTI Frankfurt eG, zbMATH, and SCImago.
                                    </div>
                                    </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            
            <div className='row p-2 mt-4'>
                <div className='col-12'>

                    <div className="card mt-2 border border-5" style={{ margin: "0px auto" }}>
                        <div className="card-header text-center font-weight-bold fs-4">
                        Submission Guidelines
                        </div>

                        <div className='row'>
                            <div className='col col-12'>
                                <ul className="list-group list-group-flush">
                                <li className="list-group-item text-justify fs-5 fontFamily">
                                    <div className='row'>
                                        <div className='col fs-5 font-weight-bold text-wrap text-dark fw-light'>
                                        Authors are kindly invited to submit their formatted full papers including results, tables, 
                                        figures and references. All submissions are handled through the CMT : <Link to="https://cmt3.research.microsoft.com/ICIDS2024" > Link</Link> 
                                    </div>
                </div>
                                    </li>
                                    <li className="list-group-item text-justify fs-5 fontFamily">
                                    <div className='row'>
                                        <div className='col fs-5 font-weight-bold text-wrap text-dark fw-light'>
                                        	Papers should be in English.
                                    </div>
                </div>
                                    </li>
                                    <li className="list-group-item text-justify fs-5 fontFamily">
                                    <div className='row'>
                                        <div className='col fs-5 font-weight-bold text-wrap text-dark fw-light'>
                                        	
                                            It is preferable for research papers to have a minimum length of 10 pages. Short papers
                                             should adhere to a minimum page length of 8 pages.
                                    </div>
                </div>
                                    </li>
                                    <li className="list-group-item text-justify fs-5 fontFamily">
                                    <div className='row'>
                                        <div className='col fs-5 font-weight-bold text-wrap text-dark fw-light'>
                                        Previously published work may not be submitted, nor may the work be concurrently
                                         submitted to any other conference or journal. Such papers will be rejected without review.
                                    </div>
                </div>
                                    </li>
                                    <li className="list-group-item text-justify fs-5 fontFamily">
                                    <div className='row'>
                                        <div className='col fs-5 font-weight-bold text-wrap text-dark fw-light'>
                                        The plagiarism should not be more than 15%.
                                    </div>
                </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
         
            <div className='p-3'>
                <Footer />
            </div>
        </div>
    </>
  )
}

export default CallForPaper