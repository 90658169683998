import React from 'react'
import mu from '../images/mu.jpg'

function HeaderComponent() {
  return (

    <div className="container-fluid" style={{clear:"both",marginTop:"90px"}}>
  <div className="row">
    <div className="col-12">
    <img src={mu} alt="Logo" className='img-fluid' />
    </div>
   
  </div>
</div>

   
  )
}

export default HeaderComponent