import React from 'react'
import Conference from './Conference'
import Footer from './Footer'
import Shitanshu from '../images/Shitanshu.jpeg'
import Surendra from '../images/Surendra.jpg'
import abhishek from '../images/abhishek.jpeg'
import ashish from '../images/ashish.jpg'
import aprna from '../images/aprna.jpg'
import dinesh from '../images/dinesh.jpg'
import shukwinder from '../images/shukwinder.jpg'
import gaurav from '../images/gaurav.jpg'
import chirag from '../images/chirag.jpg'
import vaitheeswaran from '../images/vaitheeswaran.jpg'
import nitu from '../images/nitu.jpg'
import NavbarMenu from './NavbarMenu'
import sumit from '../images/sumit.jpg'
import akhilesh from '../images/akhilesh.png'
import amit1 from '../images/amit.png'
import nnsharma from '../images/nnsharma.png'
import kuldeep from '../images/kuldeep.png'
import jangir from '../images/jangir.jpg'
import neeraj from '../images/neeraj.jpg'


function Patrons() {
  return (
    <>
    <div className='container-fluid'>
    <div className='row'>
      <div className='col col-12'>
      
        <div>
          <NavbarMenu />
        </div>
        <div className='row'>
          <div className='col col-12'>
            <Conference />
          </div>
        </div>

        <div className='row p-2'>
          <div className='col' style={{ margin: "0px auto"}}>

            <div className='' style={{ margin: "0px auto",width:"90%"}}>
              <div className="card mt-2 fontFamily" style={{ width: "100%" }}>
                <div className="card-header text-center font-weight-bold fs-4">
                  CHIEF PATRON
                </div>
                <ul className="list-group list-group-flush">
                  <li class="list-group-item text-center">
                    <p><img src={vaitheeswaran} alt="Logo" className='img-fluid border border-2 p-2' style={{width: "135px",height:"135px"}} /></p>
                    <span style={{fontSize:"18px"}}>Mr. S.Vaitheeswaran<br />
                    <span style={{fontSize:"14px"}} className='text-justify'>Chairman, Manipal University Jaipur</span></span>
                  </li>
                </ul>
              </div>
            </div>


          </div>
        </div>

        <div className='row p-2'>
          <div className='col'>

          <div className='fontFamily'>
              <div className="card mt-2" style={{ width: "90%",margin:"0px auto" }}>
                <div className="card-header text-center font-weight-bold fs-4">
                PATRON
                </div>

                <div className='row'>
                      <div className='col col-6'>
                      <ul className="list-group list-group-flush">
                  <li class="list-group-item text-center">
                    <p><img src={nnsharma} alt="Logo" className='img-fluid border border-2 p-2'  style={{width: "135px",height:"135px"}} /></p>
                    <span style={{fontSize:"18px"}}>Dr. N N Sharma<br />
                    <span style={{fontSize:"14px"}}>President, Manipal University Jaipur</span></span>
                  </li>

                  
                </ul>
                      </div>

                      <div className='col col-6'>
                      <ul className="list-group list-group-flush">
                  <li class="list-group-item text-center">
                    <p><img src={jangir} alt="Logo" className='img-fluid border border-2 p-2'  style={{width: "135px",height:"135px"}} /></p>
                    <span style={{fontSize:"18px"}}>Commodore (Dr.) Jawahar M Jangir<br />
                    <span style={{fontSize:"14px"}}>Pro-President, Manipal University Jaipur</span></span>
                  </li>

                  
                </ul>
                      </div>
                </div>

                

              </div>
            </div>

          </div>
        </div>


        <div className='row p-2'>
          <div className='col'>

          <div className='fontFamily'>
              <div className="card mt-2" style={{ width: "90%",margin:"0px auto" }}>
                <div className="card-header text-center font-weight-bold fs-4">
                CO-PATRON
                </div>

                <div className='row'>
                      <div className='col col-6'>
                      <ul className="list-group list-group-flush">
                  <li class="list-group-item text-center">
                    <p><img src={nitu} alt="Logo" className='img-fluid border border-2 p-2'  style={{width: "135px",height:"135px"}} /></p>
                    <span style={{fontSize:"18px"}}>Dr. Nitu Bhatnagar<br />
                    <span style={{fontSize:"14px"}}>Registrar, Manipal University Jaipur</span></span>
                  </li>

                  
                </ul>
                      </div>

                      <div className='col col-6'>
                      <ul className="list-group list-group-flush">
                  <li class="list-group-item text-center">
                    <p><img src={kuldeep} alt="Logo" className='img-fluid border border-2 p-2'  style={{width: "135px",height:"135px"}} /></p>
                    <span style={{fontSize:"18px"}}>Dr. Kuldip Singh Sangwan<br />
                    <span style={{fontSize:"14px"}}>Dean, Faculty of Engineering, Manipal University Jaipur</span></span>
                  </li>

                  
                </ul>
                      </div>
                </div>

                

              </div>
            </div>

          </div>
        </div>


        <div className='row p-2'>
          <div className='col'>

          <div className='fontFamily'>
              <div className="card mt-2" style={{ width: "90%",margin:"0px auto" }}>
                <div className="card-header text-center font-weight-bold fs-4">
                ORGANIZERS
                </div>

                <div className='row'>
                      <div className='col col-6'>
                      <ul className="list-group list-group-flush">
                  <li class="list-group-item text-center">
                    <p><img src={sumit} alt="Logo" className='img-fluid border border-2 p-2' style={{width: "135px",height:"135px"}} /></p>
                    <span style={{fontSize:"18px"}}>Dr. Sumit Shrivastava<br />
                    <span style={{fontSize:"14px"}}>Director<br />
                    School of Information Security and Data Science Manipal University Jaipur</span></span>
                  </li>

                  
                </ul>
                      </div>

                      <div className='col col-6'>
                      <ul className="list-group list-group-flush">
                  <li class="list-group-item text-center">
                    <p><img src={akhilesh} alt="Logo" className='img-fluid border border-2 p-2' style={{width: "135px",height:"135px"}} /></p>
                    <span style={{fontSize:"18px"}}>Dr. Akhilesh Kumar Sharma<br />
                    <span style={{fontSize:"14px"}}>HoD<br />
                    Department of Data Science and Engineering Manipal University Jaipur</span></span>
                  </li>

                  
                </ul>
                      </div>
                </div>

                

              </div>
            </div>

          </div>
        </div>


        <div className='row p-2'>
          <div className='col'>

          <div className='fontFamily'>
              <div className="card mt-2" style={{ width: "90%",margin:"0px auto" }}>
                <div className="card-header text-center font-weight-bold fs-4">
                CONVENOR
                </div>

                <div className='row'>
                      <div className='col col-4'>
                      <ul className="list-group list-group-flush">
                  <li class="list-group-item text-center">
                    <p><img src={gaurav} alt="Logo" className='img-fluid border border-2 p-2' style={{width: "135px",height:"135px"}} /></p>
                    <span style={{fontSize:"18px"}}>Dr. Gaurav Kumawat<br />
                    <span style={{fontSize:"14px"}}>Assistant Professor, Department of Data Science and Engineering, Manipal University Jaipur</span></span>
                  </li>

                  
                </ul>
                      </div>

                      <div className='col col-4'>
                      <ul className="list-group list-group-flush">
                  <li class="list-group-item text-center">
                    <p><img src={chirag} alt="Logo" className='img-fluid border border-2 p-2' style={{width: "135px",height:"135px"}} /></p>
                    <span style={{fontSize:"18px"}}>Dr. Chirag Joshi<br />
                    <span style={{fontSize:"14px"}}>Assistant Professor, Department of Data Science and Engineering, Manipal University Jaipur</span></span>
                  </li>

                  
                </ul>
                      </div>

                      <div className='col col-4'>
                      <ul className="list-group list-group-flush">
                  <li class="list-group-item text-center">
                    <p><img src={neeraj} alt="Logo" className='img-fluid border border-2 p-2' style={{width: "135px",height:"135px"}} /></p>
                    <span style={{fontSize:"18px"}}>Dr. Neeraj Kumar Verma<br />
                    <span style={{fontSize:"14px"}}>Assistant Professor, Department of Data Science and Engineering, Manipal University Jaipur</span></span>
                  </li>

                  
                </ul>
                      </div>
                </div>

                

              </div>
            </div>

          </div>
        </div>

        <div className='row p-2'>
          <div className='col'>

          <div className='fontFamily'>
              <div className="card mt-2" style={{ width: "90%",margin:"0px auto" }}>
                <div className="card-header text-center font-weight-bold fs-4">
                CO-CONVENOR
                </div>

                <div className='row'>

                <div className='col col-sm-3'>
                      <ul className="list-group list-group-flush">
                  <li class="list-group-item text-center">
                    <p><img src={amit1} alt="Logo" className='img-fluid border border-2 p-2' style={{width:"135px",height:"135px"}} /></p>
                    <span style={{fontSize:"18px"}}>Dr. Amit Joshi</span><br />
                    <span style={{fontSize:"14px"}}>Director, Global Knowledge Research Foundation</span>
                  </li>

                  
                </ul>
                      </div>

                      <div className='col col-sm-3'>
                      <ul className="list-group list-group-flush">
                  <li class="list-group-item text-center">
                    <p><img src={shukwinder} alt="Logo" className='img-fluid border border-2 p-2' style={{width:"135px",height:"135px"}} /></p>
                    <span style={{fontSize:"18px"}}>Dr. Sukhwinder Sharma</span><br />
                    <span style={{fontSize:"14px"}}>Associate Professor, Department of Data Science and Engineering, Manipal University Jaipur</span>
                  </li>

                  
                </ul>
                      </div>

                      <div className='col col-sm-3'>
                      <ul className="list-group list-group-flush">
                  <li class="list-group-item text-center">
                    <p><img src={aprna} alt="Logo" className='img-fluid border border-2 p-2' style={{width: "135px",height:"135px",minWidth:"60px",minHeight:"60px"}} /></p>
                    <span style={{fontSize:"18px"}}>Dr. Aprna Tripathi<br />
                    <span style={{fontSize:"14px"}}>Associate Professor, Department of Data Science and Engineering, Manipal University Jaipur</span></span>
                  </li>

                  
                </ul>
                      </div>

                      <div className='col col-sm-3'>
                      <ul className="list-group list-group-flush">
                  <li class="list-group-item text-center">
                    <p><img src={dinesh} alt="Logo" className='img-fluid border border-2 p-2' style={{width: "135px",height:"135px",minWidth:"60px",minHeight:"60px"}} /></p>
                    <span style={{fontSize:"18px"}}>Dr. Dinesh Sharma<br />
                    <span style={{fontSize:"14px"}}>Associate Professor, Department of Data Science and Engineering, Manipal University Jaipur</span></span>
                  </li>

                  
                </ul>
                      </div>
                </div>

                

              </div>
            </div>

          </div>
        </div>

        <div className='row p-2'>
          <div className='col'>

          <div className='fontFamily'>
              <div className="card mt-2" style={{ width: "90%",margin:"0px auto" }}>
                <div className="card-header text-center font-weight-bold fs-4">
                CONFERENCE SECRETARY
                </div>

                <div className='row'>
                     
                <div className='col col-6'>
                      <ul className="list-group list-group-flush">
                  <li class="list-group-item text-center">
                    <p><img src={Surendra} alt="Logo" className='img-fluid border border-2 p-2' style={{width: "135px",height:"135px"}} /></p>
                    <span style={{fontSize:"18px"}}>Dr. Surendra Solanki<br />
                    <span style={{fontSize:"14px"}}>Assistant Professor, Department of Artificial Intelligence and Machine Learning, Manipal University Jaipur</span></span>
                  </li>

                  
                </ul>
                      </div>

                      <div className='col col-6'>
                      <ul className="list-group list-group-flush">
                  <li class="list-group-item text-center">
                    <p><img src={abhishek} alt="Logo" className='img-fluid border border-2 p-2' style={{width: "135px",height:"135px"}} /></p>
                    <span style={{fontSize:"18px"}}>Dr. Abhishek Dwivedi<br />
                    <span style={{fontSize:"14px"}}>Assistant Professor, Department of Data Science and Engineering, Manipal University Jaipur</span></span>
                  </li>

                  
                </ul>
                      </div>
                </div>

                

              </div>
            </div>

          </div>
        </div>

        <div className='row p-2'>
          <div className='col'>

          <div className='fontFamily'>
              <div className="card mt-2" style={{ width: "90%",margin:"0px auto" }}>
                <div className="card-header text-center font-weight-bold fs-4">
                CONFERENCE CO-SECRETARY
                </div>

                <div className='row'>
                      <div className='col col-6'>
                      <ul className="list-group list-group-flush">
                  <li class="list-group-item text-center">
                    <p><img src={Shitanshu} alt="Logo" className='img-fluid border border-2 p-2' style={{width: "135px",height:"135px"}} /></p>
                    <span style={{fontSize:"18px"}}>Dr. Shitanshu Jain<br />
                    <span style={{fontSize:"14px"}}>Assistant Professor, Department of Data Science and Engineering, Manipal University Jaipur</span></span>
                  </li>

                  
                </ul>
                      </div>

                      <div className='col col-6'>
                      <ul className="list-group list-group-flush">
                  <li class="list-group-item text-center">
                    <p><img src={ashish} alt="Logo" className='img-fluid border border-2 p-2' style={{width: "135px",height:"135px"}} /></p>
                    <span style={{fontSize:"18px"}}>Mr. Ashish Pandey<br />
                    <span style={{fontSize:"14px"}}>Assistant Professor, Department of Data Science and Engineering, Manipal University Jaipur</span></span>
                  </li>

                  
                </ul>
                      </div>

                </div>

                

              </div>
            </div>

          </div>
        </div>
        <div className='mt-4'>
          <Footer />
        </div>
      </div>
      </div>
      </div>
    </>
  )
}

export default Patrons