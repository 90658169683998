import React from 'react'
import Footer from './Footer'
import NavbarMenu from './NavbarMenu'
import springer from '../images/springer.png'
import springer_nature from '../images/springer_nature.jpg'
import scopus from '../images/scopus.png'
import { Link } from 'react-router-dom'

function PaperSubmission() {
  return (
    <>

      <div className='container-fluid'>
        <div>
          <NavbarMenu />
        </div>
        <div className='row' style={{marginTop:"90px"}}>
        <div className='col col-10 p-2 mb-4' style={{ margin: "0px auto" }}>
        <div class="">
            <div class="row" style={{alignItems:"center"}}>
              <div class="col text-center">
              <img src={springer} alt="Logo" className='img-fluid border border-2 p-2' style={{width: "200px",height:"100px"}} />
              </div>
              <div class="col text-center">
              <img src={springer_nature} alt="Logo" className='img-fluid border border-2 p-2' style={{width: "200px",height:"200px"}} />
              </div>
              <div class="col text-center">
              <img src={scopus} alt="Logo" className='img-fluid border border-2 p-2' style={{width: "200px",height:"100px"}} />
              </div>
            </div>
        </div>

        </div>
         
        <div className='col col-10 p-2 mb-4 font-weight-bold fs-5 fontFamily' style={{margin:"0px auto"}}>

        <div className='row'>
          <div className='col col-12 text-center fs-4 p-2 text-wrap bgColorDemo text-darh mt-3 fontFamily' style={{margin:"0px auto"}}>
              PUBLICATION AND INDEXING 
          </div>
        </div>
        <div className='p-2 mb-4 font-weight-bold fs-5 fontFamily' style={{margin:"0px auto"}}>
            <table className="table table-striped mt-3 border">
        <tbody>
          <tr>
            <td>
              PUBLICATION
            </td>
           
          </tr>
          <tr>
          <td>
              ICIDS 2024 presented papers will be published in conference proceedings by Springer LNNS.
              book series “Lecture Notes in Networks and Systems” (LNNS) series, which is indexed by SCOPUS, EI Compendex, INSPEC, WTI Frankfurt eG, zbMATH, and SCImago. <Link to="https://www.springer.com/series/15179" > Link</Link>
             </td>
          </tr>
          </tbody>
          </table>
        </div>

        <div className='p-2 mb-4 font-weight-bold fs-5 fontFamily' style={{margin:"0px auto"}}>
            <table className="table table-striped mt-3 border">
        <tbody>
          <tr>
            <td>
              Paper Submission
            </td>
           
          </tr>
          <tr>
          <td>
          Authors are kindly invited to submit their formatted full papers including results, 
          tables, figures and references. All submissions are handled through the CMT : <Link to="https://cmt3.research.microsoft.com/ICIDS2024" > Link</Link>

             </td>
          </tr>
          </tbody>
          </table>
        </div>

        </div>
        </div>

        <div className='mt-4'>
          <Footer />
        </div>

      </div>
    </>
  )
}

export default PaperSubmission