import React from 'react'
import { NavLink } from 'react-router-dom'

function Footer() {

  return (
    <>
        <div>
        <section className="">

  <footer className="text-center text-dark p-3">
    

    <div className="text-center p-3 text-dark fontFamily" style={{backgroundColor:'rgba(0, 0, 0, 0.2)'}}>
        Copyright © ICIDS2024<br />
        International Conference on Innovations in Data Science 2024 (ICIDS - 2024)<br />
         

    </div>
                
  </footer>
  
</section>

            
            <div>
            </div>


        </div>
    </>
  )
}

export default Footer