import React from 'react'
import jaipur from '../images/jaipur.jpg'
import lalit from '../images/lalit.jpg'
import pankaj from '../images/pankaj.jpg'
import dharmendra from '../images/dharmendra.jpg'
import phayung from '../images/phayung.png'
import piyush from '../images/piyush.jfif'
import thittaporn from '../images/thittaporn.png'



function OtherComponent() {
    return (
        <>
           
            <div className='row p-2 text-justify'>
                <div className='col-12'>

                    <div className="card mt-2 border border-5" style={{ margin: "0px auto" }}>
                        <div className="card-header text-center font-weight-bold fs-4 fontFamily">
                        Welcome to ICIDS 2024
                        </div>

                        <div className='row'>
                            <div className='col col-12'>
                                <ul className="list-group list-group-flush">
                                    <li className="list-group-item text-justify fs-5 fontFamily">
                                    <div className='row'>
                        <div className='col fs-5 font-weight-bold text-wrap text-dark  fst-italic text-dark p-3 text-justify fontFamily'>
                            <p>Hello and welcome to ICIDS 2024 – where ideas converge and innovations shine! The International
                                Conference on Innovations in Data Science (ICIDS) brings together a stellar lineup of R&D
                                presentations from leading experts in the field. This conference is an excellent opportunity
                                for researchers, practitioners, and industry professionals to explore new techniques,
                                share insights, and discuss the latest advancements and trends in data science.</p>

                            <p>We are thrilled to host the ICIDS on November 28<sup>th</sup> and 29<sup>th</sup>, 2024,
                                in the vibrant city of Jaipur, India. The event will be conducted in a hybrid mode:
                                the first day (November 28th) will be held in physical mode, and the second day (November 29th)
                                will be in digital mode.</p>

                            <p>We look forward to seeing you there!</p>

                        </div>

                    </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='row p-2 mt-4'>
                <div className='col-12'>

                    <div className="card mt-2 border border-5" style={{ margin: "0px auto" }}>
                        <div className="card-header text-center font-weight-bold fs-4 fontFamily">
                        Objective of ICIDS 2024
                        </div>

                        <div className='row'>
                            <div className='col col-12'>
                                <ul className="list-group list-group-flush">
                                    <li className="list-group-item text-justify fs-5 fontFamily">
                                    <div className='col fs-5 font-weight-bold text-wrap text-dark  text-dark p-3 text-justify fontFamily'>
                            <p>The objective of the International Conference on Innovations in Data Science (ICIDS 2024) is to foster a dynamic
                                environment where researchers,
                                practitioners, and industry experts can collaboratively explore and discuss the forefront of data science innovations.
                                Our goal is to:</p>

                            <div className='row'>
                                <div className='col col-12' style={{ margin: "0px auto" }}>
                                    <p><b> 1. Promote Knowledge Exchange:</b> Facilitate the exchange of cutting-edge research, ideas, and practices in data science, providing a platform for presenting novel findings and innovative techniques.</p>

                                    <p><b> 2. Encourage Collaboration:</b> Build networks among academia, industry, and practitioners to encourage collaboration on solving complex data science problems and advancing the field.</p>

                                    <p><b> 3. Highlight Advancements:</b> Showcase the latest trends, tools, and technologies that are shaping the future of data science, from theoretical developments to practical applications.</p>

                                    <p><b> 4. Inspire Innovation:</b> Inspire participants to think creatively and develop new approaches and solutions to the challenges faced in data science.</p>

                                    <p><b> 5. Support Professional Development:</b> Provide opportunities for attendees to enhance their skills and knowledge through workshops, panel discussions, and keynote sessions led by distinguished experts.</p>


                                </div>
                            </div>
                            <p>ICIDS 2024 aims to be a landmark event that significantly contributes to the advancement of data science and fosters a global community dedicated to the field’s growth and innovation.</p>
                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className='row p-2 mt-4'>
                <div className='col-12'>

                    <div className="card mt-2 border border-5" style={{ margin: "0px auto" }}>
                        <div className="card-header text-center font-weight-bold fs-4 fontFamily">
                        About MUJ
                        </div>

                        <div className='row'>
                            <div className='col col-12'>
                                <ul className="list-group list-group-flush">
                                    <li className="list-group-item text-justify fs-5 fontFamily">
                                    Manipal University Jaipur (MUJ) is a prestigious educational institution known for its commitment to
                                    excellence in education, research, and innovation. Located in the vibrant city of Jaipur, MUJ
                                    offers a wide range of undergraduate, postgraduate, and doctoral programs across various disciplines.
                                    The university boasts state-of-the-art infrastructure, experienced faculty, and a student-centric
                                    approach. With a focus on holistic development, MUJ fosters a dynamic learning environment, preparing
                                    students for global challenges and opportunities. MUJ has also been ranked among the Top 100 Universities
                                    in India by NIRF 2024
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className='row p-2 mt-4'>
                <div className='col-12'>

                    <div className="card mt-2 border border-5" style={{ margin: "0px auto" }}>
                        <div className="card-header text-center font-weight-bold fs-4 fontFamily">
                            Keynote Speakers
                        </div>

                        <div className='row'>
                            <div className='col col-6'>
                                <ul className="list-group list-group-flush">
                                    <li className="list-group-item  text-center">
                                        <p><img src={lalit} alt="Logo" className='img-fluid border border-2 p-2' style={{ width: "135px", height: "135px" }} /></p>
                                        <p className='font-weight-bold fs-5 fontFamily'>Er. Lalit Yagnik</p>
                                        <p className='fontFamily'>Executive Leadership with Emerging Technologies<br /> 
                                            ex IBM Australia, Singapore, India<br />
                                            Co-founder/CTO AgriTwin AI, Australia
                                        </p>
                                    </li>


                                </ul>
                            </div>

                            <div className='col col-6'>
                                <ul className="list-group list-group-flush">
                                    <li className="list-group-item text-center">
                                        <p><img src={pankaj} alt="Logo" className='img-fluid border border-2 p-2' style={{ width: "135px", height: "135px" }} /></p>
                                        <p className='font-weight-bold fs-5 fontFamily'>Dr. Pankaj Kumar Porwal</p>
                                        <p className='fontFamily'>Director, Data Science (DS/ML Labs) @ Cars24<br />Ph.D (Cornell University), B.Tech (IIT, Bombay) </p>
                                    </li>


                                </ul>
                            </div>

                        </div>


                        <div className='row'>
                            <div className='col col-6'>
                                <ul className="list-group list-group-flush">
                                    <li className="list-group-item  text-center">
                                        <p><img src={dharmendra} alt="Logo" className='img-fluid border border-2 p-2' style={{ width: "135px", height: "135px" }} /></p>
                                        <p className='font-weight-bold fs-5 fontFamily'>Prof. Dharmender Singh Kushwaha</p>
                                        <p className='fontFamily'>Professor, Department of Computer Science & Engineering<br />Motilal Nehru National Institute of Technology Allahabad, Prayagraj, India
                                        </p>
                                    </li>


                                </ul>
                            </div>

                            <div className='col col-6'>
                                <ul className="list-group list-group-flush">
                                    <li className="list-group-item text-center">
                                        <p><img src={phayung} alt="Logo" className='img-fluid border border-2 p-2' style={{ width: "135px", height: "135px" }} /></p>
                                        <p className='font-weight-bold fs-5 fontFamily'>Dr. Phayung Meesad</p>
                                        <p className='fontFamily'>Associate Professor, Faculty of Information Technology and Digital Innovation<br />King Mongkut's University of Technology North Bangkok (KMUTNB), Thailand</p>
                                    </li>

                                </ul>
                            </div>

                        </div>

                        <div className='row'>
                            <div className='col col-6'>
                                <ul className="list-group list-group-flush">
                                    <li className="list-group-item  text-center">
                                        <p><img src={piyush} alt="Logo" className='img-fluid border border-2 p-2' style={{ width: "135px", height: "135px" }} /></p>
                                        <p className='font-weight-bold fs-5 fontFamily'>Mr. Piyush Khopkar</p>
                                        <p className='fontFamily'>Sr. Software Engineer- Machine Learning @ MathWorks,
                                        NASA/JPL Solar System Ambassador<br />Ex- MIT-MOXIE/NASA/Mars 2020</p>
                                    </li>

                                </ul>
                            </div>

                        
                            <div className='col col-6'>
                                <ul className="list-group list-group-flush">
                                    <li className="list-group-item  text-center">
                                        <p><img src={thittaporn} alt="Logo" className='img-fluid border border-2 p-2' style={{ width: "135px", height: "135px" }} /></p>
                                        <p className='font-weight-bold fs-5 fontFamily'>Dr. Thittaporn Ganokratanaa</p>
                                        <p className='fontFamily'>Professor<br />King Mongkut’s University of Technology Thonburi, Thailand</p>
                                    </li>

                                </ul>
                            </div>

                        </div>

                    </div>

                </div>
            </div>


            <div className='row p-2 mt-4'>
                <div className='col-12'>

                    <div className="card mt-2 border border-5" style={{ margin: "0px auto" }}>
                        <div className="card-header text-center font-weight-bold fs-4 fontFamily">
                        About Jaipur
                        </div>

                        <div className='row'>
                            <div className='col col-12'>
                                <ul className="list-group list-group-flush">
                                    <li className="list-group-item text-justify fs-5 fontFamily text-center">
                                    <img src={jaipur} alt="Logo" className='img-fluid' style={{width:"100%",height:"200px"}} />
                                    </li>
                                </ul>
                                <ul className="list-group list-group-flush">
                                    <li class="list-group-item text-justify fs-5 fontFamily">
                                    Jaipur, the capital of Rajasthan, India, is famously known as the "Pink City" due to its distinctively
                                    colored buildings. Founded in 1727 by Maharaja Sawai Jai Singh II, it is one of India's first planned cities,
                                    designed according to Vastu Shastra principles. Jaipur's architecture blends Rajasthani and Mughal styles,
                                    epitomized by landmarks such as the City Palace, Hawa Mahal, and Amber Fort. The city also hosts the Jantar
                                    Mantar, an astronomical observatory and UNESCO World Heritage site. Jaipur is a cultural hub, renowned for
                                    its festivals like the Jaipur Literature Festival, Teej, and the Elephant Festival, which draw visitors from
                                    around the globe. The local cuisine, including dishes like Dal Baati Churma and Laal Maas, is a culinary delight.
                                    The city is also a major center for arts and crafts, particularly famous for its jewelry, textiles, and blue pottery.
                                    Economically, tourism is a significant contributor, alongside a thriving gemstone and jewelry industry.
                                    The city also has a growing IT sector and robust educational infrastructure, with institutions
                                    like the University of Rajasthan and Malaviya National Institute of Technology. Modern developments
                                    include the Jaipur Metro, enhancing urban connectivity. Designated a UNESCO World Heritage City in 2019,
                                    Jaipur balances its historical heritage with contemporary advancements. With a semi-arid climate,
                                    it experiences hot summers, a monsoon season, and mild winters. Jaipur's rich history, vibrant culture,
                                    and modern amenities make it a captivating destination for both tourists and residents.
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default OtherComponent