import React from 'react'
import NavbarMenu from './NavbarMenu'
import Conference from './Conference'


function ConferenceTrack() {
  return (
    <>
         <div className='container-fluid fontFamily'>
         <div>
          <NavbarMenu />
        </div>
        <div className='row'>
                <div className='col'>
                    <Conference />
                </div>
            </div>
        <div className='row p-2 mt-4'>
                <div className='col-12'>

                    <div className="card mt-2 border border-5" style={{ margin: "0px auto" }}>
                        <div className="card-header text-center font-weight-bold fs-4">
                        Conference Tracks
                        </div>
                        <div className='col col-10 p-2 mb-4' style={{ margin: "0px auto" }}>

        </div>

                        <div className='row'>
                            <div className='col col-12'>
                                <ul className="list-group list-group-flush">
                                    <li className="list-group-item text-justify fs-5 fontFamily">
                                    <div className='row'>
                                        <div className='col fs-5 font-weight-bold text-wrap text-dark  fw-light p-3'>
                                        <div className="card mt-2" style={{width:"90%",margin:"0px auto"}}>
                        <div className="card-header text-center font-weight-bold fs-4">
                            Track 1: Data Science in Medicine
                        </div>
                        <ul className="list-group list-group-flush">
                            <li class="list-group-item text-center">
                                Medical Diagnosis with AI
                            </li>
                        </ul>
                        <ul className="list-group list-group-flush">
                            <li class="list-group-item text-center">
                            AI-powered Radiology and Imaging
                            </li>
                        </ul>
                        <ul className="list-group list-group-flush">
                            <li class="list-group-item text-center">
                            Natural Language Processing in Healthcare
                            </li>
                        </ul>
                        <ul className="list-group list-group-flush">
                            <li class="list-group-item text-center">
                            Predictive Analytics in Healthcare
                            </li>
                        </ul>
                        <ul className="list-group list-group-flush">
                            <li class="list-group-item text-center">
                            AI in Clinical Decision Support
                            </li>
                        </ul>
                        <ul className="list-group list-group-flush">
                            <li class="list-group-item text-center">
                            AI for Drug Repurposing
                            </li>
                        </ul>
                        <ul className="list-group list-group-flush">
                            <li class="list-group-item text-center">
                            AI-enhanced Surgical Systems
                            </li>
                        </ul> 
             </div>


             <div className="card mt-5" style={{width:"90%",margin:"0px auto"}}>
                        <div className="card-header text-center font-weight-bold fs-4">
                            Track 2: Data Science in Autonomous Systems
                        </div>
                        <ul className="list-group list-group-flush">
                            <li class="list-group-item text-center">
                            AI for Autonomous Vehicles
                            </li>
                        </ul>
                        <ul className="list-group list-group-flush">
                            <li class="list-group-item text-center">
                            Machine Learning for Autonomous Drones
                            </li>
                        </ul>
                        <ul className="list-group list-group-flush">
                            <li class="list-group-item text-center">
                            AI in Self-navigating Robots
                            </li>
                        </ul>
                        <ul className="list-group list-group-flush">
                            <li class="list-group-item text-center">
                            Natural Language Processing in Human-Robot Interaction
                            </li>
                        </ul>
                        <ul className="list-group list-group-flush">
                            <li class="list-group-item text-center">
                            AI-driven Smart Cities and Infrastructure
                            </li>
                        </ul>
                        <ul className="list-group list-group-flush">
                            <li class="list-group-item text-center">
                            Reinforcement Learning in Autonomous Systems
                            </li>
                        </ul>
                        <ul className="list-group list-group-flush">
                            <li class="list-group-item text-center">
                            AI in Agriculture Automation
                            </li>
                        </ul>                       
             </div>

             <div className="card mt-5" style={{width:"90%",margin:"0px auto"}}>
                        <div className="card-header text-center font-weight-bold fs-4">
                        Track 3: Machine Learning
                        </div>
                        <ul className="list-group list-group-flush">
                            <li class="list-group-item text-center">
                            Machine Learning Optimization
                            </li>
                        </ul>
                        <ul className="list-group list-group-flush">
                            <li class="list-group-item text-center">
                            Unsupervised Learning
                            </li>
                        </ul>
                        <ul className="list-group list-group-flush">
                            <li class="list-group-item text-center">
                            Supervised Learning
                            </li>
                        </ul>
                        <ul className="list-group list-group-flush">
                            <li class="list-group-item text-center">
                            Hybrid Machine Learning Systems for Data Science
                            </li>
                        </ul>
                        <ul className="list-group list-group-flush">
                            <li class="list-group-item text-center">
                            Data Science and Machine Learning
                            </li>
                        </ul>
                        <ul className="list-group list-group-flush">
                            <li class="list-group-item text-center">
                            Generative AI
                            </li>
                        </ul>
                        <ul className="list-group list-group-flush">
                            <li class="list-group-item text-center">
                            Transfer Learning
                            </li>
                        </ul>                       
             </div>


             <div className="card mt-2" style={{width:"90%",margin:"0px auto"}}>
                        <div className="card-header text-center font-weight-bold fs-4">
                            Track 4: Deep Learning and Data Science
                        </div>
                        <ul className="list-group list-group-flush">
                            <li class="list-group-item text-center">
                            Deep Neural Networks Optimization Algorithms
                            </li>
                        </ul>
                        <ul className="list-group list-group-flush">
                            <li class="list-group-item text-center">
                            Deep Recurrent Neural Networks
                            </li>
                        </ul>
                        <ul className="list-group list-group-flush">
                            <li class="list-group-item text-center">
                            Deep Feedforward Networks
                            </li>
                        </ul>
                        <ul className="list-group list-group-flush">
                            <li class="list-group-item text-center">
                            Inference Dependencies on Multi-Layered Networks
                            </li>
                        </ul>
                        <ul className="list-group list-group-flush">
                            <li class="list-group-item text-center">
                            Sequence Modelling
                            </li>
                        </ul>
                        <ul className="list-group list-group-flush">
                            <li class="list-group-item text-center">
                            Deep Convolutional Neural Networks
                            </li>
                        </ul>
                        <ul className="list-group list-group-flush">
                            <li class="list-group-item text-center">
                            Multi-Scale Deep Architecture and Learning
                            </li>
                        </ul>                       
             </div>

                                        </div>
                                    </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default ConferenceTrack